.surgery{
    margin-bottom: 0;
    h2{
        font-size: 50px;
        line-height: 50px;
        text-align: center;
        margin-bottom: 60px;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Medium';
        font-weight: 500;
        // text-transform: uppercase;
        @media screen and (max-width: 991px){
            font-size: 28px;
            line-height: 50px; 
            margin-bottom: 40px;
        }
        @media screen and (max-width: 767px){
            line-height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img{
            margin-right: 48px;
            @media screen and (max-width: 991px){
                width: 90px;
                margin-right: 39px;
            }
            @media screen and (max-width: 767px){
                width: 54px;
                margin-right: 12px;
            }
        }
    }
}

.testimonial01{
    margin-bottom: 87px;
    position: relative;
    
    @media screen and (max-width: 991px){
        margin-bottom: 40px;
    }
    @media screen and (max-width: 767px){
        margin-bottom: 47px;
        text-align: center
    }
    img{
        width: 265px;
        @media screen and (max-width: 991px){
            max-width: 147px;
        }
        @media screen and (max-width: 767px){
            max-width: 100px;
            margin-bottom: 24px;
        }
    }
    p{
        margin-bottom: 22px;
        font-size: 20px;
        line-height: 26px;
        &:first-child{
            &:after{
                background: url('../../img/group-3.svg') no-repeat 20% top;
                background-size: cover;
                content: "";
                position: absolute;
                top: -53px;
                left: -77px;
                z-index: -1;
                height: 88px;
                width: 109px;
                @media screen and (max-width: 991px){
                    display: none;
                }
            }
        }
        @media screen and (max-width: 991px){
            font-size: 16px;
        }
        @media screen and (max-width: 767px){
            font-size: 14px;
            line-height: 18px;
            text-align: left;
        }
        @media screen and (max-width: 576px){
            font-size: 16px;
            line-height: 19px;
        }
        span{
            background-color: #e6eaee;
            border-radius: 14px;
            padding:0 4px;
            font-weight: 600;
            // display: inline-block;
        }
    }
    h3{
        font-size: 26px;
        line-height: 23px;
        margin-bottom: 22px;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Bold';
        font-weight: 500;
        @media screen and (max-width: 991px){
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 13px;
        }
        @media screen and (max-width: 767px){
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 32px;
        }
        @media screen and (max-width: 576px){
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 32px;
        }
    }
    .videoPlay{
        font-size: 18px;
        
        @media screen and (max-width: 991px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 767px){
            font-size: 14px;
            border-radius: 18px;
            background-color: #19232d;
            display: inline-block;
            padding: 9px 22px;
            margin: 0 auto;
            color: #fff;
            text-align: center;
            line-height: 10px;
            &:before{
                content: "";
                width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-left: 13px solid #fff;
                border-bottom: 8px solid transparent;
                margin-right:5px;
                display: inline-block;
                vertical-align: middle;

            }
        }
        img{
            width: 52px;
            margin-right: 15px;
            @media screen and (max-width: 991px){
                width: 32px;
            }
            @media screen and (max-width: 767px){
                display: none;
                width: 25px;
            }
        }
    }
}

.sectionDes{
    margin-bottom: 80px;
    padding-top: 20px;
    margin-top: 80px;
    @media screen and (max-width: 991px){
        padding-top: 60px;
        margin-bottom: 60px;
    }
    @media screen and (max-width: 767px){
        padding-top: 34px;
        margin-bottom: 40px;
    }
    img{
        @media screen and (max-width: 767px){
            margin-bottom:  20px;
        }
    }
    h4{
        // font-family: 'Conv_Mark Simonson - Proxima Nova Bold';
        font-weight: 700;
        margin-bottom: 26px;
        @media screen and (max-width: 991px){
            font-size: 20px;
            line-height: 24px;
        }

    }
    .contentWrap{
        @media screen and (min-width: 992px){
            // max-width: 60%;
            // margin: 0 auto 40px;
        }
        p{
            
            @media screen and (min-width: 767px){
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    p{
        line-height: 22px;
        @media screen and (max-width: 991px){
            font-size: 16px;
            line-height: 20px;
        }
    }
    &.des1{
        background: url(../../img/mask-pink.png) no-repeat left center;
        background-size: contain; 
        @media screen and (max-width: 991px){
            background: url(../../img/mask-pink-tablet.png) no-repeat left center;
            background-size: contain; 
        }
        @media screen and (max-width: 767px){
            background: url(../../img/mobile-mask.png) no-repeat left top;
            background-size: contain; 
        }
        img{
            @media screen and (min-width: 992px){
                // margin-left:10%;
            }
        }
    }
    &.des2{
        background-color: #fcf1ff;
        img{
            @media screen and (min-width: 992px){
                // margin-right:10%;
            }
        }
    }
    &.des3{
        background-color: #ffe9de;
        img{
            @media screen and (min-width: 992px){
                // margin-left:10%;
            }
        }
    }
    &.des4{
        background: url(../../img/gMask-desktop.png) no-repeat right center;
        background-size: auto 100%; 
        @media screen and (max-width: 991px){
            background: url(../../img/gMask-tablet.png) no-repeat right center;
            background-size: auto 100%; 
        }
        @media screen and (max-width: 767px){
            background: url(../../img/gMask-mobile.png) no-repeat right top;
            background-size: contain; 
        }
        img{
            @media screen and (min-width: 992px){
                // margin-right:10%;
            }
        }
    }
    &.forpatientdes4{
        background: url(../../img/forpatient1@3x.png) no-repeat right center;
        background-size: auto 100%; 
        @media screen and (max-width: 991px){
            background: url(../../img/forpatient1@2x.png) no-repeat right center;
            background-size: auto 100%; 
        }
        @media screen and (max-width: 767px){
            background: url(../../img/forpatient1.png) no-repeat right top;
            background-size: contain; 
        }
        img{
            @media screen and (min-width: 992px){
                // margin-right:10%;
            }
        }
    }
    &.forpatientdes1{
        background: url(../../img/forpatient2@3x.png) no-repeat left center;
        background-size: contain; 
        @media screen and (max-width: 991px){
            background: url(../../img/forpatient2@2x.png) no-repeat left center;
            background-size: contain; 
        }
        @media screen and (max-width: 767px){
            background: url(../../img/forpatient2.png) no-repeat left top;
            background-size: contain; 
        }
        img{
            @media screen and (min-width: 992px){
                // margin-left:10%;
            }
        }
    }
    &.des5{
        background-color: #ffe7e5;
        img{
            @media screen and (min-width: 992px){
                // margin-right:10%;
            }
        }
    }
    &.des6{
        background-color: #ebf5ff;
        img{
            @media screen and (min-width: 992px){
                // margin-right:10%;
            }
        }
    }
}

.docDes{
    padding: 100px 0 ;
    @media screen and (max-width: 991px){
        padding: 0 0 50px;
    }
    @media screen and (max-width: 767px){
        padding: 44px 0;
        background-color: #fff8ed;
    }
    img{
        @media screen and (max-width: 991px){
            width: 181px;
        }
        @media screen and (max-width: 767px){
            width: 72px;
            margin-bottom: 20px;
        }
    }
    h2{
        font-size: 34px;
        line-height: 38px;
        margin-bottom: 38px;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Semibold';
        font-weight:600;
        @media screen and (max-width: 991px){
            font-size: 21px;
            line-height: 30px;
            margin-bottom: 16px;
        }
        @media screen and (max-width: 767px){
            font-size: 22px;
            line-height: 24px;
            margin-bottom: 24px;
        }
    }
    p{
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
        @media screen and (max-width: 991px){
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 24px;
        }
        @media screen and (max-width: 767px){
            font-size: 16px;
        }
    }
    .learn-more{
        color: #2d3e4f;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Medium';
        font-weight: 500;
    }
}

.benefitsPlan{
    background-color: #fcf1ff;
    padding: 68px 0 100px;
    @media screen and (max-width: 991px){
        padding: 60px 0;
    }
    @media screen and (max-width: 767px){
        padding: 35px 0; 
    }
    .swiper-button-prev:after, .swiper-button-next:after{
        color: #6e477b;
        font-size: 10px;
        @media screen and (max-width: 767px){
            font-size: 19px;
        }
    }
    .swiper-button-next{
        right: 0;
        top: 38%;
        // background: url('../../img/oval.png') no-repeat;
        height: 39px;
        width: 39px;
    }
    .swiper-button-prev{
        top: 38%;
        left: 0;
    }
    h6{
        font-size: 27px;
        text-align: center;
        margin-bottom: 49px;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Semibold';
        font-weight: 600;
        color: #6e477b;
        @media screen and (max-width: 991px){
            font-size: 24px;    
            margin-bottom: 30px;
        }
        @media screen and (max-width: 767px){
            font-size: 16px;
            line-height:20px; 
            text-align: left;
            padding: 0 10px;
            margin-bottom: 22px;
        }
          @media screen and (max-width: 576px){
            font-size: 18px !important;
            line-height:20px !important;
        }
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;


        @media screen and (max-width: 576px){
            a {
                font-size: 12px !important;
                line-height: 17px !important;
            }
        }
        
  
        /*display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;*/

        img{
            margin-bottom: 21px;
            @media screen and (max-width: 991px){
                width: 162px;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 767px){
                width: 80px;
                margin-bottom: 10px;
            }
        }
        h5{
            font-size: 20px;
            // line-height: 30px;
            // line-height: 15px;
            margin-bottom: 1px;
            color: #6e477b;
            // font-family: 'Conv_Mark Simonson - Proxima Nova Bold';
            font-weight: 700;
            @media screen and (max-width: 991px){
                font-size: 16px;
                // line-height: 20px;
            }
            @media screen and (max-width: 767px){
                font-size: 12px;
                // line-height: 30px;
            }
            @media screen and (max-width: 576px){
                font-size: 14px;
                line-height: 18px;
            }
        }
        p{
            font-size: 16px;
            color: #6e477b;
            @media screen and (max-width: 991px){
                font-size: 14px;
            }
            @media screen and (max-width: 767px){
                font-size: 10px;
            }
        }
        a:hover{
            text-decoration: none;
        }
    }
}

footer{
    .footer-bottom{
        margin: 0 105px;
        @media screen  and (max-width:991px){
            margin: 0 70px;
        }
        @media screen  and (max-width:767px){
            margin: 0 40px;
        }
    }
}

.mail-us {
    .footer-font {
        font-weight: 700;
        font-size: 24px;
        line-height: 45px;
        text-align: center;
        color: #E28816;
        .footer-contact-us {
            font-weight: 500;
            color: #fff;
            text-decoration: underline;
            margin-left: 10px;
        }
    }
}


.care-pathways-link{
  justify-content: center;
  margin: auto;
  display: flex;
 a{
    color: #19222c;
    font-size: 20px;
    text-decoration: underline;
    background-size: 48px auto;
    /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    // padding: 10px 35px 11px 85px;
    padding: 10px 50px 11px 30px;
    display: inline-block;
    text-align: center;
    background: #005bb7 url('../../img/white-arrow@3x.png') no-repeat 335px;
    border-radius: 31px;
    background-size: 28px auto;
    }
    @media screen and  (max-width: 1024px) {
       margin: 40px auto;
    }
}

@media screen  and (max-width: 512px){

 .care-pathways-link {  
   a { 
    color: #19222c;
    text-decoration: underline;
    background-size: 48px auto;
    font-size: 1rem;
    /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    padding: 15px 36px 16px 16px;
    display: inline-block;
    text-align: center;
    background: #005bb7 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAARCAYAAAAougcOAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGaADAAQAAAABAAAAEQAAAABog9yfAAAAi0lEQVQ4EWNgoAD8///fCYhdKTCCsFagBU+BGASyCKsmUwXQ8HSwFRCCphZljVpEViwBgw1r0DECJZyAJtqRZSp2TSZAYW+oVDYjI+M0kCVPgQJS2NVTLPofaIIuC5CIBWJa+SQH6JOrFDsV2QBccYKshiL2qAUEgw8YRLQvu0DJH4hBgKaFI1H1CQCO0cPPiU7lBgAAAABJRU5ErkJggg==) no-repeat 254px;
    border-radius: 31px;
    background-size: 24px auto;
    }
 }
}

.sliderImg {
    max-width: 200px;
}

.screenShotImages {
    max-width: 520px;
    width:100%;
}
.docName {
    font-weight: 700;
}

.customBgWhite {
    @media screen and (max-width: 767px){
        background-color: white;
        padding-top: 20px;
    }
}
