.vertical-center {
    display: flex;
    align-items: center;
}
.vertical-baseline {
    display: flex;
    align-items: baseline;
}
.border-bottom {
    border-bottom: solid 1px #e5e3e3;
}
.seenByAnna {
    float: right;
    padding: 12px 0;
    font-size: 15px;
    font-weight: 500;
    color: #333;
    @media screen and (max-width: 991px){
        font-size: 12px;
    }
}
.timelineHeader{
    margin-bottom: 77px;
    margin-top: 20px;
    position: relative;
    
    @media screen and (max-width: 991px){
        margin-bottom: 20px;
    }
    @media screen and (max-width: 767px){
        margin-bottom: 14px;
        text-align: center
    }
    h3{
        font-size: 42px;
        font-weight: bold;
        line-height: 1.19;
        margin-bottom: 8px;
        color: #2d3e4f;
        @media screen and (max-width: 991px){
            font-size: 32px;
            line-height: 1.03;
            margin-bottom: 13px;
        }
    }
    .heading-desc {
        font-size: 18px;
        color: #2d3e4f;
        line-height: 22px;
        font-weight: normal;
        @media screen and (max-width: 767px){
            font-size: 18px;
            font-weight: normal;
            line-height: 22px;
        }
    }
    .headerCalendar {
        width: 87px;
        height: 87px;
        border-radius: 9px;
        background-color: #dfecf9;
        color: #2d3e4f;
        .month {
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 0;
            line-height: 1.7;
            @media screen and (max-width: 991px){
                font-size: 22px;
                line-height: 1.6;
            }
        }
        
        .day {
            font-size: 40px;
            font-weight: 600;
            line-height: normal;
            line-height: 0.9;
            @media screen and (max-width: 991px){
                font-size: 30px;
                line-height: 0.7;
            }
        }
        @media screen and (max-width: 991px){
            width: 64px;
            height: 64px;
        }
    }
}

.timelineBody {
    margin-left: 30px;
    @media screen and (max-width: 767px){
        margin-left: 8px;
    }
    .timeline {
        position: relative;
        padding: 0;
        list-style: none;
        .oval {
            width: 16px;
            height: 16px;
            background-color: #2d3e4f;
            left: 37px;
            position: absolute;
            border-radius: 100%;
            @media screen and (max-width: 767px){
                left: 9px;
            }
        } 
        .dash {
            left: 24px;
            position: absolute;
            border-top: 2px solid #2d3e4f;
            width: 42px;
            @media screen and (max-width: 767px){
                width: 26px;
                left: 5px;
            }
        }       
        .calendar {
            width: 80px;
            height: 80px;
            z-index: 20;
            position: absolute;
            top: 50px;
            left: -80px;
            color: #525252;            
            @media screen and (max-width: 991px){
                top: 145px;
                width: 45px;
                height: 48px;                
                left: -36px;
            }
            @media screen and (max-width: 767px){
                top: 95px;
            }
            .month {
                font-size: 26px;
                font-weight: 600;
                margin-bottom: 0;
                background-color: #eeeeee;
                line-height: 1.6;
                border-top-left-radius: 9px;
                border-top-right-radius: 9px;
                @media screen and (max-width: 991px){
                    font-size: 14px;
                    line-height: 1.75;
                }
            }            
            .day {
                font-size: 40px;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 0;
                background-color: #f8f8f8;
                border-bottom-left-radius: 9px;
                border-bottom-right-radius: 9px;
                @media screen and (max-width: 991px){
                    font-size: 20px;
                    line-height: 1.2;
                }
            }
            .time {
                margin-top: 8px;
                margin-bottom: 16px;
                font-size: 14px;
                color: #525252;
                @media screen and (max-width: 991px){
                    font-size: 10px;
                }
            }
        }
        .comment {
            color: #333333;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            top: 160px;
            left: -102px;
            position: absolute;
            margin-top: 10px;
            @media screen and (max-width: 991px){
                display: none;
            }
        }
        li {
            position: relative;
            .timelinePanel {
                max-width: 470px;
                @media screen and (max-width: 991px){
                    width: 226px;
                }
                @media screen and (max-width: 767px){
                    width: 214px;
                    margin-top: 21px;
                }
                .timeline-body {
                    p {
                        color: #333;
                        @media screen and (max-width: 991px){
                            font-size: 12px;
                        }
                    }
                }
                .speechBox {
                    position: relative;
                    padding: 20px;
                    border: solid 1px #cccccc;
                    border-radius: 8px;
                    border-bottom-left-radius: 0;
                    @media screen and (max-width: 991px){
                        padding: 13px;
                    }
                }
                .speechBox:after {
                    content: " ";
                    position: absolute;
                    left: -14px;
                    bottom: 0px;
                    border-bottom: 15px solid #fff;
                    border-left: 15px solid transparent;
                    border-bottom-left-radius: 2px;
                }
                .speechBox:before {
                    content: " ";
                    position: absolute;                
                    left:-16px;
                    bottom: -1px;
                    border-bottom: 16px solid #ccc;
                    border-left: 16px solid transparent;
                    border-bottom-left-radius: 5px;
                }
            }
            .timeline-row {                
                margin-left: 46px;
                @media screen and (max-width: 767px){
                    margin-left: 18px;
                }
            }
            .center-col {
                display: flex;
                justify-content: center;
            }
            .timelinePanel-2 {
                max-width: 330px;
                @media screen and (max-width: 991px){
                    width: 238px
                }
                @media screen and (max-width: 767px){
                    width: 181px;
                    margin-top: 21px;
                }
                .timeline-body {
                    margin-top: 8px;
                    p {
                        font-size: 16px;
                        margin-bottom: 0px;
                        @media screen and (max-width: 991px){
                            font-size: 12px;
                        }
                    }
                }
            }
            .timelinePanel-3 {
                color: #833e93;
                font-weight: 600;
                max-width: 450px;
                h3 {
                    font-size: 19px;
                    font-weight: 600;
                    color: #833e93;
                    text-align: center;
                    margin-bottom: 28px;
                    @media screen and (max-width: 767px){
                        font-size: 14px;
                    }
                }
                img {
                    padding: 0 16px;
                }
                @media screen and (max-width: 991px){
                    padding-top: 42px;
                }
                @media screen and (max-width: 767px){
                }
            }
            .timelinePanel-4 {
                .timeline-body {
                    @media screen and (max-width: 767px){
                        margin-top: 21px;
                    }
                }
                .task-box {
                    font-size: 18px;
                    font-weight: 600;
                    .article-text {
                        font-size: 12px;
                        font-weight: 500;
                        color: #666;
                        text-decoration: underline;
                        img {
                            width: 12px;
                            height: 16px;
                            margin-right: 8px;
                        }
                    }
                }
                .task-box:first-child {
                    color: #666;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                .task-box:not(:first-child) {
                    padding: 24px 41px;
                    width: 365px;
                    color: #333;
                    margin-bottom: 16px;
                }
                @media screen and (max-width: 991px){
                    .task-box {
                        font-size: 14px;
                    }
                    .task-box:not(:first-child) {
                        padding: 20px 16px;
                        width: 236px;
                    }
                }
            }
            .timelinePanel-5 {
                font-weight: 600;
                width: 100%;
                color: #333;
                .timeline-heading {
                    p {
                        font-size: 22px;
                        font-weight: 600;
                        @media screen and (max-width: 991px){
                            font-size: 14px;
                        }
                        @media screen and (max-width: 767px){
                            margin-bottom: 0px;
                        }
                        img {
                            @media screen and (max-width: 767px){
                                height: 30px;
                            }
                        }
                    }
                }
                img {
                    padding: 0 16px;
                    height: 36px;
                    @media screen and (max-width: 767px){
                        padding: 0 10px;
                        height: 45px;
                    }
                }
                .number-counter {
                    width: 340px;
                    align-items: flex-end;
                    margin-bottom: 30px;
                    .col:first-child {
                        display: flex;
                        justify-content: center;
                    }
                    .col:last-child {
                        display: flex;
                        justify-content: center;
                    }
                }
                .options {
                    border-radius: 30px;
                    background-color: #eeeeee;
                    width: 144px;
                    padding: 10px;
                    margin: 6px 12px;
                    font-weight: normal;
                    text-align: center;
                    font-size: 14px;
                    @media screen and (max-width: 991px){
                        font-size: 11px;
                        width: 100px;
                        padding: 3px;
                        margin: 8px;
                        line-height: 1.3;
                    }
                }
                .selected {                    
                    opacity: 1;
                    margin: 6px 12px;
                    border-radius: 30px;
                    @keyframes scale {
                        0% {
                            opacity: 0.5;
                            border: solid 1px #eee;
                            background-color: #eee;
                            color: #333;
                        }
                        100% {
                            opacity: 1;
                            border: solid 1px #e28816;
                            background-color: #fff4e6;
                            color: #e28816;
                        }
                    }
                    .options {
                        margin: 0;
                        border: solid 1px #e28816;
                        background-color: #fff4e6;
                        color: #e28816;
                        font-weight: 600;
                        animation: scale 1s;
                    }
                }
                .comp-1 {
                    width: 340px;
                    @media screen and (max-width: 991px){
                        width: 248px;
                    }
                }
                .comp-2 {
                    width: 300px;
                    @media screen and (max-width: 991px){
                        width: 240px;
                    }
                }
                .comp-3 {
                    width: 325.5px;
                    @media screen and (max-width: 991px){
                        width: 240px;
                    }
                    img {
                        @media screen and (max-width: 767px){
                            height: 26px;
                        }
                    }
                }
                .comp-4 {
                    color: #333;
                    width: 340px;
                    @media screen and (max-width: 991px){
                        width: 280px;
                    }
                    img {
                        @media screen and (max-width: 991px){
                            padding: 0 10px;
                        }
                        @media screen and (max-width: 767px){
                            height: 26px;
                        }
                    }
                    .medication.meds-description {
                    line-height: 0.5;
                    }                
                    .meds-description {
                        font-size: 16px;
                        @media screen and (max-width: 991px){
                           font-size: 14px;
                        }
                        p {
                            font-size: 12px !important;
                            display: inline-block;
                            @media screen and (max-width: 991px){
                                font-size: 11px !important;
                            }
                        }
                    }
                    .disabled-text {
                        color: #999;
                    }
                    .meds-label {
                        font-size: 11px;
                        color: #666;
                        @media screen and (max-width: 991px){
                            font-size: 11px;
                        }
                    }
                }
                .comp-5 {
                    width: 231px;
                    @media screen and (max-width: 991px){
                        width: 216px;
                    }
                    .sleep {
                        font-size: 22px;
                        font-weight: 500;
                        color: #333;
                        border-bottom: solid 1px #c8c8c8;
                        padding: 0px 14px;
                        text-align: center;
                        height: 42px;
                        @media screen and (max-width: 991px){
                            div {
                                margin: 0 !important;
                            }
                        }
                    }
                    .sleep-title {
                        font-size: 12px;
                        font-weight: 500;
                    }
                    img {
                        padding: 0;
                    }
                }
                .comp-6 {
                    color: #333333;
                    width: 333px;
                    .title {
                        font-size: 12px;
                        span {
                            font-weight: 600;
                        }
                    }
                    .vertical-bar {
                        .bar {
                            width: 6px;
                            height: 62px;
                            background-color: #2d3e4f;
                            margin: auto;
                        }
                        // .bar 
                    }
                }
                .timeline-body{
                    @media screen and (max-width: 767px){
                        padding-bottom: 0px !important;
                        margin-left: 12px;
                    }
                    p {
                        margin: 1rem 15px;
                        font-size: 18px !important;
                        color: #27303a;
                        font-weight: normal;
                        line-height: 22px;
                        @media screen and (max-width: 991px){
                            font-size: 15px;
                            font-weight: normal;
                            line-height: 19px;
                        }
                        @media screen and (max-width: 767px){
                            font-size: 14px !important;
                            font-weight: normal;
                            line-height: 18px !important;
                        }
                    }
                    .count {
                        border-bottom: solid 1px #c8c8c8;
                        padding: 0px 14px;
                        font-size: 50px;
                        font-weight: 300;
                        width: 160px;
                        text-align: center;
                        height: 62px;
                        div:not(:first-child){
                            font-size: 14px;
                            color: #999;
                            font-weight: normal;
                            line-height: 2.5;
                        }
                        @media screen and (max-width: 1130px){
                            font-size: 32px;
                            height: 44px;
                        }
                        @media screen and (max-width: 767px){
                            top: -8px;
                        }
                    }
                    .log {
                        border-bottom: solid 1px #e5e3e3;
                        @media screen and (max-width: 767px){
                            padding-bottom: 12px !important;
                            padding-top: 8px !important;
                        }
                    }
                    .value {
                        font-size: 16px;
                        font-weight: 700;
                        @media screen and (max-width: 991px){
                            font-size: 14px;
                        }
                    }
                    .label {
                        font-size: 16px;
                        @media screen and (max-width: 991px){
                            font-size: 12px;
                        }
                    }
                    .content {
                        color: #666666;
                        font-size: 12px;
                        font-weight: normal;
                        @media screen and (max-width: 991px){
                            font-size: 11px;
                        }
                    }
                    .bar-1 {
                        max-width: 107px;
                        height: 12px;
                        background-color: #e0a148;
                        animation: grow 2s 0s ease 1 forwards;
                        transform-origin: left;
                        @keyframes grow {
                            0% {
                                transform: scaleX(0);
                            }
                            100% {
                                transform: scaleX(107px);
                            }
                        }
                    }
                    .bar-2 {
                        max-width: 65px;
                        height: 12px;
                        background-color: #598542;
                        animation: grow 2s 0s ease 1 forwards;
                        transform-origin: left;
                        @keyframes grow {
                            0% {
                                transform: scaleX(0);
                            }
                            100% {
                                transform: scaleX(107px);
                            }
                        }
                    }
                    .bar-3 {
                        max-width: 37px;
                        height: 12px;
                        background-color: #ff5454;
                        animation: grow 2s 0s ease 1 forwards;
                        transform-origin: left;
                        @keyframes grow {
                            0% {
                                transform: scaleX(0);
                            }
                            100% {
                                transform: scaleX(107px);
                            }
                        }
                    }
                    .bar-name {
                        font-size: 12px;
                    }
                    .bar-tag {
                        font-size: 11px;
                        text-align: right;
                    }
                    .bowel-img-2 {
                        padding-right: 12px !important;
                        height: 16px;
                    }
                }
            }
            .timelinePanel-5.second {
                @media screen and (max-width: 767px){
                    margin-top: 40px;
                }
            }
            .timelinePanel-6 {
                width: 100%;
                font-size: 14px;
                width: 360px;
                @media screen and (max-width: 991px){
                    width: 254px;
                }
                .section-related {
                    font-size: 12px;
                    @media screen and (max-width: 991px){
                        display: none;
                    }
                }
                img {
                    padding: 0 12px;
                }
                .timeline-heading {
                    font-size: 16px;
                    font-weight: bold;
                    @media screen and (max-width: 991px){
                        font-size: 14px;
                    }
                }
                .timeline-heading.overlap {
                    position: absolute;
                    top: 11px;
                    background-color: #fff;
                    margin: 0 20px;
                    padding-right: 10px;
                    z-index: 1;
                }
                .timeline-body {
                    position: relative;
                    .summary {
                        padding: 28px 0 12px 0;;
                        border: solid 2px #FAE8D1;
                        max-width: 360px;
                        font-size: 12px;
                        @media screen and (max-width: 991px){
                            width: 254px;
                        }
                        .row {
                            padding: 6px 0;
                        }
                        & > div {
                            align-items: center;
                        }
                    }
                }
                .des {
                    font-size: 14px;
                    font-weight: 600;
                    text-align: right;
                    @media screen and (max-width: 991px){
                        padding: 0;
                        text-align: center;
                        font-size: 12px;
                    }
                }
                p {
                    padding: 20px 0;
                    @media screen and (max-width: 767px){
                        padding-bottom: 11px;
                        padding-top: 15px;
                    }
                    margin-bottom: 0;
                    .bold {
                        font-weight: 600;;
                    }
                }
                .button {
                    width: 62px;
                    height: 24px;
                    border-radius: 12px;
                    border: solid 1px #2d3e4f;
                    text-align: center;
                    padding: 0;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: 700;
                    @media screen and (max-width: 767px){
                        font-size: 8px;
                        width: 56px;
                    }
                }
                .typing {
                    border: solid 1px #333333;
                    max-height: 52px;
                    font-size: 12px;
                    @media screen and (max-width: 767px){
                        font-size: 10px;
                        padding: 8px 4px !important;
                    }
                    .type-steps {
                        white-space: nowrap;
                        overflow: hidden;
                        width: 100%;
                        opacity: 0;
                        border-right: .15em solid #e28816;
                        -webkit-animation: type 1.8s steps(40, end), blink .5s step-end infinite alternate;
                        animation: type 1.8s steps(40, end), blink .5s step-end infinite alternate;
                        -webkit-animation-fill-mode: forwards;
                        animation-fill-mode: forwards;
                    }
                    @keyframes type {
                        0% {
                            width: 0;
                        }
                        1% {
                            opacity: 1;
                        }
                        100% {
                            opacity: 1;
                        }
                      }
                    @-webkit-keyframes type {
                        0% {
                            width: 0;
                        }
                        1% {
                            opacity: 1;
                        }
                        100% {
                            opacity: 1;
                        }
                      }
                    @keyframes blink {
                        50% {
                          border-color: transparent;
                        }
                      }
                    @-webkit-keyframes blink {
                        50% {
                          border-color: tranparent;
                        }
                      }
                }
                .typing:first-child {
                    border-right: none !important;
                }
                .typing.disabled {
                    border: solid 1px #ccc;
                    color: #999;
                }
                .details-head {
                    @media screen and (max-width: 767px){
                        font-size: 12px;
                        padding: 8px 0 !important;
                    }
                }
                .details {
                    @media screen and (max-width: 767px){
                        font-size: 11px;
                        padding: 8px 0 !important;
                    }
                }
            }
            .timelinePanel-6.diagnostic {
                @media screen and (max-width: 767px){
                    margin-top: 40px;
                }
            }
            .timelinePanel-7 {
                font-size: 14px;
                width: 390px;
                margin: auto;
                @media screen and (max-width: 991px){
                    margin-left: 0px;
                }
                @media screen and (max-width: 767px){
                    margin-left: 20px;
                    font-size: 11px;
                }
                .timeline-heading {
                    border-top-right-radius: 100px;
                    border-bottom-right-radius: 100px;
                    font-weight: 700;
                    font-size: 22px;
                    color: #293e51;
                    width: fit-content;
                    @media screen and (max-width: 991px){
                        font-size: 16px;
                        padding: 0;
                    }
                    @media screen and (max-width: 767px){
                        font-size: 14px;
                        background-color: transparent !important;
                    }
                    div {
                        line-height: 1;
                        padding: 9px 22px;
                        @media screen and (max-width: 767px){
                            padding: 0px;
                        }
                        img {
                            padding-left: 0px;
                            height: 21px;
                        }
                    }
                    img {
                        padding-left: 26px;
                    }
                }
                img {
                    padding: 0 16px;
                }
                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    padding-left: 32px;
                    @media screen and (max-width: 991px){
                        font-size: 14px;
                    }
                    @media screen and (max-width: 767px){
                        font-size: 12px;
                    }
                }
                .appointment-list {
                    ul {
                        display: inline-block;
                        list-style: none;
                        line-height: 1.69;
                        font-size: 16px;
                        @media screen and (max-width: 991px){
                            font-size: 14px;
                        }
                        @media screen and (max-width: 767px){
                            font-size: 11px;
                        }
                        li::before {
                            content: "•"; color: #000;
                            width: 1.3em;
                            margin-left: -0.5em;
                            display: inline-block;
                            position: absolute;
                        }
                        li>span {
                            padding-left: 10px;
                            display: inline-block;
                        }
                    }
                }
                .heading {
                    font-size: 20px;
                    font-weight: 600;
                    @media screen and (max-width: 991px){
                        font-size: 14px;
                    }
                    @media screen and (max-width: 767px){
                    }
                }
                .summary {
                    .row {
                        margin-left: 32px;
                        padding-bottom: 12px !important;
                        @media screen and (max-width: 767px){
                            padding: 5px 0!important;
                        }
                        img {
                            @media screen and (max-width: 767px){
                                padding: 2px 5px !important;
                            }
                        }
                    }
                }
                .horizontal-timeline {
                    ol, li {
                        margin: 0;
                        padding: 0;
                        color: #999;
                        font-size: 10px;
                    }
                    ol {
                        display: flex;
                        justify-content: space-between;
                        position: relative;
                        max-width: 280px;
                        margin-top: 30px;
                        
                        &:after {
                          content: '';
                          display: block;
                          width: 100%;
                          height: 2px;
                          background-color: #999;
                          position: absolute;
                          top: 50%;
                          left: 0;
                          transform: translate(0, -50%);
                        }
                    }                      
                    li {
                        list-style: none;
                        position: relative;
                        
                        &:after {
                          content: '';
                          display: block;
                          width: 6px;
                          height: 6px;
                          border-radius: 50%;
                          background-color: #999;
                        }
                    }
                    span {
                        position: absolute;
                        top: calc(100% + 5px);
                        left: 50%;
                        transform: translate(-50%, 0);
                        display: inline-block;
                        text-align: center;
                        width: 40px;
                    }
                    .content-box {
                        background-color: #333;
                        width: 47px;
                        height: 15px;
                        position: absolute;
                        top: -20px;
                        left: 28px;
                        color: #fff;
                        text-align: center;
                        font-size: 10px;
                        font-weight: 500;
                        padding-top: 1.6px;
                        @media screen and (max-width: 767px){
                            left: 98px;
                        }
                        @media screen and (max-width: 410px){
                            left: 48px;
                        }
                    }
                    .content-box:before {
                        content: " ";
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -8px;
                        border: 4px solid transparent;
                        border-top-color: #333;
                    }
                }
            }
            .timelinePanel-8 {
                border-style: solid;
                border-width: 1px;
                border-image-source: linear-gradient(to bottom, #cccccc, #dfdfdf 32%, #ffffff);
                border-image-slice: 1;
                padding: 35px 27px;
                margin: 0 100px;
                -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
                mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
                @media screen and (max-width: 991px){
                    margin: 0 35px;
                }
                @media screen and (max-width: 767px){
                    padding: 16px 17px;
                    margin: 25px 15px;
                }
                p {
                    color: #333333;
                    font-size: 18px;
                    @media screen and (max-width: 767px){
                        font-size: 12px;
                    }
                }
            }
            .timelinePanel-9 {
                max-width: 330px;
                @media screen and (max-width: 991px){
                    width: 238px
                }
                @media screen and (max-width: 767px){
                    width: 181px;
                    margin-top: 21px;
                }
                .timeline-body {
                    margin-top: 8px;
                    p {
                        font-size: 16px;
                        margin-bottom: 0px;
                        @media screen and (max-width: 991px){
                            font-size: 12px;
                        }
                    }
                    .sub-heading {
                        font-size: 20px;
                        font-weight: 600;
                        margin-top: 12px;
                        margin-bottom: 22px;
                    }
                }
            }
            .timelinePanel-10 {
                max-width: 330px;
                @media screen and (max-width: 991px){
                    width: 238px
                }
                @media screen and (max-width: 767px){
                    width: 181px;
                    margin-top: 21px;
                }
                .sub-heading {
                    font-size: 22px;
                    font-weight: 600;
                }
                .timeline-body {
                    margin-top: 16px;
                    p {
                        font-size: 12px;
                        margin-bottom: 0px;
                        @media screen and (max-width: 991px){
                            font-size: 10px;
                        }
                    }
                }
            }
            @mixin styling($badge-size) {
                .timeline-badge {
                    z-index: 20;
                    position: absolute;
                    width: $badge-size;
                    height: $badge-size;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 1.4em;
                    line-height: 50px;
                    color: #fff;
                }                
                .timeline-wrapper {
                    position: relative;
                    // Setting badge at midway for all timelinewrapper
                    .timeline-badge {
                        left: - ($badge-size / 2);
                        top: calc(50% - #{$badge-size/2});
                        @media screen and (max-width: 767px){
                            top: -15px;
                        }
                    }
                }
                .timeline-badge.no-header {
                    top: auto !important;
                    left: - ($badge-size / 2);
                }
            }
            // Standard badge size
            @media screen and (min-width: 991px){
                @include styling($badge-size: 56px);
            }
            @media screen and (max-width: 991px){
                @include styling($badge-size: 46px);
            }
            @media screen and (max-width: 767px){
                @include styling($badge-size: 30px);
            }
            .timeline-badge.message {
                background: url(../../img/message-icon.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.learn {
                background: url(../../img/learn-icon.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.questionnaires {
                background: url(../../img/questionnaires-icon.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.checklist {
                background: url(../../img/checklist-icon.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.excercise {
                background: url(../../img/group-121.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.mind {
                background: url(../../img/mind.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.symptoms {
                background: url(../../img/symptoms-icon.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.vitals {
                background: url(../../img/vitals-icon.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.lifestyle {
                background: url(../../img/group-94.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.medications {
                background: url(../../img/group-133.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.appointment {
                background: url(../../img/appointment-planning-icon.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.facesheet {
                background: url(../../img/facesheet-icon.svg) no-repeat left center;
                background-size: contain;
            }
            .timeline-badge.note {
                background: url(../../img/charting-icon.svg) no-repeat left center;
                background-size: contain;
            }
        }
        & > li:not(:first-child):not(:last-child){            
            padding: 0 0 180px 0;
            @media screen and (max-width: 991px){
                padding: 50px 0;
            }
            @media screen and (max-width: 767px){
                padding-bottom: 0px;
            }
        }
        & > li:first-child {
            height: 90px;
            @media screen and (max-width: 767px){
                height: 49px;
            }
        }
        li:before,
        li:after {
            content: " ";
            display: table;
        }
        // Vertical line for specific li
        & > li:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 44px;
            width: 2px;
            @media screen and (max-width: 767px){
                left: 16px;
            }
        }
        li:after {
            clear: both;
        }        
        @media screen and (max-width: 991px){
            margin-left: 33px;
        }
    }
    .timeline:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 45px;
        bottom: 0;
        width: 2px;
        margin-left: -1.5px;
        background-color: #2d3e4f;
        @media screen and (max-width: 767px){
            left: 17px;
        }
    }
    .timeline-wrapper {
        border-bottom-right-radius: 9999px;
        border-top-right-radius: 9999px;
        padding: 30px 60px 30px 67px;        
        @media screen and (max-width: 991px){
            font-size: 14px;
            padding: 20px 30px 20px 37px;
        }
        @media screen and (max-width: 767px){
            font-size: 12px;
            padding: 20px 14px 20px 17px;
        }
    }
    $clinical-care: #2d3e4f;
    .clinicalCare {
        color: $clinical-care;
        background: #ebf5ff;
    }
    .clinical-care::before {
        background-color: $clinical-care;
    }
    $patient-color: #833e93;
    .patientEdu {
        color: $patient-color;
        background: #faf5fc;
    }
    .patient-edu::before {
        background-color: $patient-color;
    }
    $questionnaire-color: #833e93;
    .intakeQuestionnaires {
        color: $questionnaire-color;
        background: #faf5fc;
    }
    .intake-questionnaires::before {
        background-color: $questionnaire-color;
    }
    $compliance-color: #b8431f;
    .complianceChecklists {
        color: $compliance-color;
        background: #ffebe4;
    }    
    .compliance-checklists::before {
        background-color: $compliance-color;
    }
    $pre-post-excercise-color: #2d3e4f;
    .pre-post-excercise::before {
        background-color: $pre-post-excercise-color;
    }
    .prePostExcercise {
        color: $pre-post-excercise-color;
        background: #ebf5ff;
    }
    $mindfulness-excercise-color: #2d3e4f;
    .mindfulness-excercise::before {
        background-color: $mindfulness-excercise-color;
    }
    .mindfulnessExcercise {
        color: $mindfulness-excercise-color;
        background: #ebf5ff;
    }
    $lifestyle-color: #2d3e4f;
    $lifestyle-color-symptom: #e28816;
    $lifestyle-color-vital: #709f34;
    .lifestyleVitals {
        color: $lifestyle-color;
        background: #ebf5ff;
    }
    .lifestyle-vitals::before {
        background-color: $lifestyle-color;
    }
    .symptom::before {
        background-color: $lifestyle-color-symptom;
    }
    .vital::before {
        background-color: $lifestyle-color-vital;
    }
    .food::before {
        background-color: $lifestyle-color;
    }
    $medications-color: #c45531;
    .medicationsAdherence::before {
        background-color: $medications-color;
    }
    $appoitment-planning: #2d3e4f;
    .appointment-planning::before {
        background-color: $appoitment-planning;
    }
    .appointmentPlanning {
        color: $appoitment-planning;
        background: #ebf5ff;
    }
    $facesheet-color: #333333;
    .printFacesheet {
        color: $facesheet-color;
        background: #eeeeee;
    }
    .print-facesheet::before {
        background-color: $facesheet-color;
    }
    $chart-color: #333333;
    .chartNote {
        color: $chart-color;
        background: #eeeeee;
    }
    h4 {
        font-size: 32px;
        font-weight: 600;
        line-height: 39px;
        @media screen and (max-width: 991px){
            font-size: 15px;
        }
        @media screen and (max-width: 767px){
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            max-width: 185px;
        }
    }
    h3 {
        font-size: 22px;
        color: #333;
        font-weight: bold;
        @media screen and (max-width: 991px){
            font-size: 20px;
        }
        @media screen and (max-width: 767px){
            font-size: 18px;
        }
    }
    .timelineBody {
        p {
            font-size: 16px;
        }
    }

}

.link-button-image{    
    margin-top: 66px;
    margin-bottom: 90px;
    .button-image {
        width: 587px;
        height: 55px;
        font-size: 24px;
        font-weight: 500;
        background-color: #2d3e4f;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center; 
        color: #fff;
        @media screen and (max-width: 991px){
            font-size: 20px;
            width: 454px;
            height: 45px;
        }
        @media screen and (max-width: 767px){
            font-size: 18px;
            width: 340px;
    
        }
    }
    .button-image:hover {
        text-decoration: none;
    }
    @media screen and (max-width: 767px){
        margin: 32px 0 60px 0;

    }
}
.success-copy {
    text-align: center;
    .button {
        max-width: 600px;
        padding: 0 80px;
        display: inline-block;
        @media screen and (max-width: 991px){
            line-height: 50px;
            font-size: 20px;
        }
        @media screen and (max-width: 767px){
            line-height: 45px;
            font-size: 18px;
        }
    }
}

.scrollBarWrapper {
    @media screen and (max-width: 767px){
        display: none;
        padding: none !important;
    }
}

#pageScrollBar {
    height: 61px;
    object-fit: contain;
    border-radius: 34px;
    box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 0 20px;
    z-index: 5;
    @media screen and (max-width: 991px){
        height: 47px;
    }
    @media screen and (max-width: 767px){
        display: none;
    }
    .col {
        text-align: center;
        @media screen and (max-width: 991px){
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.stickyBar {
    position: fixed;
    bottom: 0;
    width: 55%;
    @media screen and (max-width: 991px){
        width: 70%;
    }
}
.stickyBar.section-item-10 {
    width: 67%; 
}
.stickyBar.section-item-9 {
    width: 63%; 
}
.stickyBar.section-item-7 {
    width: 45%; 
}

.timelineFooter {
    @media screen and (max-width: 767px){
        margin-top: 50px;
    }
    h3 {
        font-size: 30px;
        font-weight: 600;
        color: #2d3e4f;
        text-align: left;
        @media screen and (max-width: 991px){
            font-size: 28px;
            text-align: center;
        }
        @media screen and (max-width: 767px){
            font-size: 18px;
        }
    }
    .footerList {
        font-size: 20px;
        line-height: 2;
        margin-top: 20px;
        @media screen and (max-width: 991px){
            font-size: 18px;
            margin-top: 40px;
        }
        @media screen and (max-width: 767px){
            font-size: 14px;
        }
    }
    ul.footerList {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;
    }
    ul.footerList > li:before {
        display: inline-block;
        content: "-";
        width: 1.5em;
        margin-left: -1em;
    }
}

.benefitsPlan{
    background-color: #fcf1ff;
    padding: 68px 0 100px;
    @media screen and (max-width: 991px){
        padding: 60px 0;
    }
    @media screen and (max-width: 767px){
        padding: 35px 0; 
    }
    .swiper-button-prev:after, .swiper-button-next:after{
        color: #6e477b;
        font-size: 10px;
        font-weight: 700;
        @media screen and (max-width: 767px){
            font-size: 19px;
        }
    }
    .swiper-button-next{
        right: 0;
        top: 38%;
    }
    .swiper-button-prev{
        top: 38%;
        left: 0;
    }
    h6{
        font-size: 27px;
        text-align: center;
        margin-bottom: 49px;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Semibold';
        font-weight: 600;
        color: #6e477b;
        @media screen and (max-width: 991px){
            font-size: 24px;    
            margin-bottom: 30px;
        }
        @media screen and (max-width: 767px){
            font-size: 16px;
            line-height:20px; 
            text-align: left;
            padding: 0 10px;
            margin-bottom: 22px;
        }
      
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
  
        /*display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;*/

        img{
            margin-bottom: 21px;
            @media screen and (max-width: 991px){
                width: 162px;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 767px){
                width: 80px;
                margin-bottom: 10px;
            }
        }
        h5{
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 1px;
            color: #6e477b;
            // font-family: 'Conv_Mark Simonson - Proxima Nova Bold';
            font-weight: 700;
            @media screen and (max-width: 991px){
                font-size: 16px;
                line-height: 20px;
            }
            @media screen and (max-width: 767px){
                font-size: 12px;
                line-height: 30px;
            }
        }
        a{
            font-size: 18px;
            color: #6e477b;
            @media screen and (max-width: 991px){
                font-size: 14px;
            }
            @media screen and (max-width: 767px){
                font-size: 10px;
            }
        }
    }
}

.carouselWrapper {
    position: relative;
    // overflow: hidden;
    margin: 0 auto;
    width: 990px;
    .carousel {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease;
        .card {
            display: inline-block;
        }
    }
}

footer{
    .footer-bottom{
        margin: 0 105px;
        @media screen  and (max-width:991px){
            margin: 0 70px;
        }
        @media screen  and (max-width:767px){
            margin: 0 40px;
        }
    }
}

// Carousel
.carousel {
    position: relative;
    min-height: 441px;
    @media screen and (max-width: 767px){
        min-height: 167px;
    }
    .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .carousel-item {
        position: relative;
        display: none;
        float: left;
        width: 100%;
        margin-right: -100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform .6s ease-in-out;
    }
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
        display: block;
    }
    .carousel-item-next:not(.carousel-item-left),
    .active.carousel-item-right {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

    .carousel-item-prev:not(.carousel-item-right),
    .active.carousel-item-left {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 15;
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
    }
}

.timeline-body{
    @media screen and (max-width: 767px){
        //  max-width: 350px; 
        max-width: 185px; 
    }


    .mb-0 {
        font-size: 18px !important;
        color: #27303a;
        font-weight: normal;
        line-height: 22px;
        @media screen and (max-width: 991px){
            font-size: 15px;
            font-weight: normal;
            line-height: 19px;
        }
        @media screen and (max-width: 767px){
            font-size: 16px !important;
            font-weight: normal;
            line-height: 20px !important;
        }
    }    
} 