.contactUsForm {
    // padding: 69px 100px;
    position: relative;
    background-image:  url('../../img/joinusBg.png') , url('../../img/mask.png');
    background-position:  7% 80%,center right;
    background-repeat: no-repeat, no-repeat;
    background-size: auto auto,90% 100%;
    margin: 80px 0;
    @media screen and (max-width: 991px) {
        padding: 36px 0 36px;
        background-size: auto 200px, 90% 100%;
        background-position: 4% 80%,center right;
    }
    @media screen and (max-width: 767px) {
        background-position: 90% top, top right;
        background-size: 150px auto, 90% 190px;
        margin-left: 10px;
    }
    h2{
        font-weight: 700;
        @media screen and (max-width: 991px) {
            font-size: 28px;
            line-height:32px;
        }
        @media screen and (max-width: 767px) {
            font-size: 22px;
        }
    }
    .subHeading{
        font-size: 20px;
        line-height: 25px;
        max-width: 270px;
        @media screen and (max-width: 991px){
            font-size: 16px;
            line-height: 20px;
        }
        @media screen and (max-width: 767px) {
            max-width: 160px;
            font-size: 14px;
            line-height: 18px;
        }
    }
    .formBlock {
        border-radius: 8px;
        box-shadow: 0 5px 25px 0 rgba(90, 103, 111, 0.17);
        margin-top: 0;
        @media screen and (max-width: 767px) {
            margin-top: 60px;
            margin-right: 10px;
        }
        .formHeader {
            padding: 18px 24px 16px;
            background: #f0f0f0;
            @media screen and (max-width: 991px) {
                padding: 10px 12px;
            }
            @media screen and (max-width: 767px) {
                padding: 9px 14x;
            }
            ul {
                padding: 0;
                display: flex;
                list-style: none;
                align-items: center;
                margin: 0;
                li {
                    flex-basis: 0;
                    flex-grow: 1;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 16px;
                    color: #2D3E50;
                    @media screen and (max-width: 767px) {
                        text-align: center;
                        flex-basis : auto;
                        margin-right: 5px;
                    }
                    span {
                        display: inline-block;
                        margin-right: 10px;
                        width: 42px;
                        height: 42px;
                        background-color: #fff;
                        border-radius: 100%;
                        padding: 11px;
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
        .formBody {
            padding: 43px 70px 44px;
            background: #fff;
            @media screen and (max-width: 991px) {
                padding: 25px;
            }
            @media screen and (max-width: 767px) {
                padding: 30px 0px;
            }
            h3 {
                text-align: center;
                font-weight: 500;
                font-size: 22px;
                line-height: 24px;
                @media screen and (max-width: 991px) {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: left;
                    padding: 7px 0px;
                }
                @media screen and (max-width: 767px) {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: left;
                    padding: 0 10px 10px 10px;
                }   
            }
            .formRow{
                margin-bottom: 14px;
                label{
                    padding: 18px 0 17px;
                    margin-bottom: 0;
                    text-align: right;
                    display: block;
                    @media screen and (max-width: 991px) {
                        font-size: 12px;
                        padding:7px 0;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        padding: 0;
                    }   
                }
                textarea{
                    resize: vertical !important;
                }
                .formField{
                    padding: 18px 19px 17px;
                    width: 100%;
                    border-radius: 8px;
                    box-shadow: inset -2px 2px 2px 0 rgba(215, 215, 213, 0.22);
                    border: solid 1px #dbdbdb;
                    background-color: #f5f5f5;     
                    // color: #b1b1b1;  
                    @media screen and (max-width: 991px) {
                        padding:7px 13px 7px;
                        font-size: 12px;
                    }          
                    
                    ::placeholder{
                        color: #b1b1b1;               
                    }
                }
                .submitBtn {
                    border-radius: 30px;
                    background-color: #2d3e50;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    color: #ffffff;
                    padding: 11px 40px 11px;
                    text-transform: uppercase;
                    border: 0;
                    @media screen and (max-width: 991px) {
                        font-size: 10px;
                        line-height: 18px;
                        padding: 4px 23px 4px;
                    }
                }
                .submitBtn:disabled {
                    cursor: not-allowed;
                    opacity: 0.7;
                }
            }
        }
    }
}
.footer-plain {
    background-color: #ffffff;
}

.error {
    border: solid 1px #da4e4e !important;
    background-color: #fdeeee !important;
}
input.error::after {
   content: 'Required';
//    color: #da4e4e !important;
}
.errorTxt{
    color: #da4e4e !important;;
    font-size: 14px;
}
.successMsg {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #2d3e50;
}
