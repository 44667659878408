@media screen and (min-width: 1440px) {
  .timeline {
    padding: 80px 90px 80px 100px!important;
    background-size: 55% auto !important;
  }
  .timeline h3 {
    font-size: 40px !important;
    line-height: 52px !important;
  }
  .timeline .steps-container {
    margin-top: 150px !important;
  }
  .no-pad {
    padding: 0 !important;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 100vw;
  }
  .container-sm {
    max-width: 100vw;
  }
  .container-md {
    max-width: 100vw;
  }
  .container-lg {
    max-width: 100vw;
  }
  .container-xl {
    max-width: 100vw;
  }
  .schedule-container {
    display: none !important;
  }
  .timeline {
    margin-top: 60px;
    padding: 70px;
    background-size: 60% auto;
  }
  .timeline h3 {
    font-weight: 400;
    font-size: 35px;
    line-height: 45px;
  }
  .timeline .steps-container {
    margin-top: 100px;
    padding: 0;
  }
  .timeline .steps-container div {
    height: auto;
    min-height: 200px;
    padding: 25px 20px;
    text-align: left;
    margin-bottom: 20px;
  }
  .navbar {
    padding: 20px 45px 20px;
  }
  .banner .big-text {
    left: 45px !important;
  }
  
  .banner .small-text {
    left: 45px !important;
  }
}

@media screen and (max-width: 1199px) {
  .navbar {
    padding: 20px 45px 20px 15px;
  } 
  .navbar-light .navbar-toggler {
    position: absolute;
    right: 25px;
    top: 28px;
    padding-right: 0;
    z-index: 100;
  }
  .navbar-collapse {
    width: 100vw;
    height: calc(100vh);
    position: absolute;
    top: 0;
    left: 0;
    padding: 85px 40px 60px 40px;
    background-color: #fff;
  }
  
  .navbar .dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 20px;
    
  }
  .practice .practice-content ul li {
    margin-bottom: 20px;
    padding-left: 40px;
  }
  .practice .practice-content ul li .category-icon {
    height: 20px;
    left: 2px;
    top: 6px;
    z-index: 2;
  }
  .practice .practice-content ul li a {
    font-size: 16px;
    padding-top: 8px;
  }
  .practice .practice-content ul li a.active:after {
    width: 450px;
    padding: 20px 0;
  }
  header a.contact-us-btn{
    display: none;
  }
  .navbar .dropdown-menu li span{
    width: 80px;
    display: inline-block;
  }
  .navbar .dropdown-menu .dropWrap{
    box-shadow: none;
  }
  .navbar-light .navbar-nav .nav-link:before{
    display: none;
  }
  .navbar .megamenu ul li {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    padding: 0;
  }
  .dropdown-menu {
    display: block;
    border: 0;
  }
  .dropdown-menu h5 {
    font-size: 16px;
  }
  header a.contact-us-btn {
    font-size: 14px;
    position: absolute;
    top: 16px;
    right: 72px;
  }
  .schedule-container #schedule-demo-btn {
    border-radius: 45px;
    width: 200px;
    height: 45px;
    padding-top: 6px;
    font-size: 16px;
    margin-top: 30px;
  }
  .banner .big-text {
    left: 15px !important;
  }
  
  .banner .small-text {
    left: 15px !important;
  }
  .outcome {
    padding-top: 48px;
    margin-bottom: 48px;
  }
  .outcome .card {
    height: 375px;
  }
  .outcome .marginBottom {
    padding-bottom: 40px;
  }
  .outcome .card .understatement {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  .outcome h3 {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    margin: 0 auto 48px;
  }
  .outcome .number {
    font-size: 32px;
    line-height: 35px;
  }

  .outcome .card p {
    font-weight: 300;
    font-size: 19px;
    line-height: 25px;
  }
  .outcome img {
    margin-bottom: 20px;
    width: 122px;
  }

  .timeline {
    margin-top: 60px;
    padding: 30px;
    background-size: 44% auto;
  }
  .timeline h3 {
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
  }
  .timeline .steps-container {
    margin-top: 75px;
    padding: 0;
  }
  .timeline .steps-container div {
    height: auto;
    min-height: 200px;
    padding: 25px 20px;
    text-align: left;
    margin-bottom: 20px;
  }

  .practice .practice-content ul li {
    margin-bottom: 20px;
  }
  .practice .practice-content ul li .category-icon {
    height: 20px;
    top: 5px;
  }
}

@media screen and (min-width: 991px) {
  .navbar .nav-item {
    padding: .5rem .5rem;
    margin-right: 16px;
  }
  .navbar .dropdown {
    position: static;
  }
  .navbar .dropdown:hover .dropdown-menu {
    display: block;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0s, opacity 0.3s linear;
    transition: visibility 0s, opacity 0.3s linear;
  }
  .navbar .dropdown .dropdown-menu:hover {
    display: block;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0s, opacity 0.3s linear;
    transition: visibility 0s, opacity 0.3s linear;
  }
  .navbar .dropdown-menu {
    width: 100%;
    left: 0;
    right: 0;
    top: 64px;
    padding-top: 25px;
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s, opacity 0.3s linear;
    transition: visibility 0s, opacity 0.3s linear;
    border: 0;
    background-color:transparent;
  }
  
  .megamenu h5 {
    padding: 28px 0 26px;
    text-align: center;
    background-color: #f1f1f1;
    margin-bottom: 26px;
  }
  .healthcare .healthRow{
    padding: 0 100px;
  }
}

@media screen and (max-width: 991px) {
  .navbar-light .navbar-toggler {
    position: absolute;
    right: 25px;
    top: 25px;
    padding-right: 0;
  }
  .navbar-toggler span {
    height: 3px;
    width: 20px;
    margin-top: 4px;
    margin-bottom: 4px;
    position: relative;
    left: 0;
  }
  
  .navbar-toggler span:nth-child(2) {
    width: 15px;
    left: 5px;
  }
  #contactUsBtn {
    margin-right: 22px;
  }
  .show ul {
    border-top: 1px solid #293E51
  }
  .show ul li {
    margin-top: 30px;
  }

  .spacer {
    height: 70px;
  }
  #schedule-demo-btn {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    height: 45px;
    width: 420px;
  }
  header .nav-item {
    margin-right: 0;
    padding: 0 10px;
  }
  header .nav-item:hover {
    background-color: #e9f5ff;
  }
  header .nav-item.active {
    background-color: #e9f5ff;
  }
  header .nav-item.dropdown:hover {
    background: transparent;
  }
  .navbar-brand img {
    width: 161px;
  }
  .dropdown-menu {
    display: block;
    border: 0;
  }
  .dropdown-menu h5 {
    font-size: 16px;
  }
  .banner .big-text {
    position: absolute;
    width: 579px;
    height: 150px;
    left: 15px;
    top: 58%;
    font-weight: 700;
    font-size: 62.6154px;
    line-height: 74px;
    color: #FFFFFF;
    z-index: 15;
  }
  
  .banner .small-text {
    position: absolute;
    width: 572px;
    height: 90px;
    left: 15px;
    top: 80%;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF;
    z-index: 15;
  }
  .banner .banner-content {
    margin-left: 0;
  }
  .banner .banner-content h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .banner .banner-content h3:before {
    width: 6px;
    height: 6px;
  }
  .banner .banner-content h3:after {
    width: 6px;
    height: 6px;
  }
  .banner .banner-content p {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 32px;
    font-weight: 500;
  }
  .banner .banner-content a.see-plan {
    font-size: 14px;
    padding: 10px 35px 11px 73px;
    background-size: 20px auto;
  }
  .banner .banner-content a.see-plan img {
    width: 48px;
  }
  .healthcare a.blue-btn{
    background-size: 20px auto;
    padding: 17px 35px 15px 73px;
    background-position: 40px center;
  }
  .trusted-by {
    padding: 37px 0 36px;
  }
  .trusted-by ul li {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
    margin-bottom: 14px;
    padding: 10px;
  }
  .trusted-by h4 {
    font-size: 20px;
    margin-bottom: 45px;
    font-weight: normal;
  }
  .timeline {
    margin-top: 60px;
    padding: 30px;
    background-size: 60% auto;
  }

  .healthcare {
    padding: 50px 0 68px;
    background-size: 50% 133px;
  }
  .healthcare h2 {
    margin-bottom: 97px;
  }
  .healthcare h4 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  .healthcare p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 40px;
  }
  .fts-52 {
    font-size: 28px;
    line-height: 34px;
  }
  .blue-btn {
    font-size: 22px;
    padding: 14px 118px 13px;
  }
  a.blue-btn {
    font-size: 22px;
    padding: 14px 118px 13px;
  }
  .practice {
    padding: 70px 0 53px;
  }
  .practice .heading-content {
    margin-bottom: 40px;
  }
  .practice .heading-content h2 {
    margin-bottom: 28px;
  }
  .practice .heading-content p {
    font-size: 18px;
    line-height: 24px;
    max-width: 100%;
  }
  .practice .practice-content ul {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    list-style: none;
    margin-top: 30px;
  }
  .practice .practice-content .col-md-4 {
    padding: 0;
  }


  .practice .practice-content {
    /* margin: 0 0;
    width: 90vw; */
  }
  .practice .practice-content ul li {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 30px;
    position: relative;
  }
  .practice .practice-content ul li .category-icon {
    height: 18px;
    position: absolute;
    left: 1px;
    top: 5px;
    z-index: 2;
  }
  .practice .practice-content ul li a {
    font-size: 11px;
    padding-top: 8px;
  }
  .practice .practice-content ul li a.active {
    font-weight: 700;
  }
  .practice .practice-content ul li a.active:after{
    position: absolute;
    width: 350px;
    left: -45px;
    top: -1px;
    padding: 15px 0;
  }
  .fts-42 {
    font-size: 32px;
    line-height: 34px;
  }
  .about {
    padding: 70px 0 81px;
    border-radius: 100px 100px 0 0;
  }
  .about h2 {
    margin-bottom: 58px;
    font-weight: 500;
    font-size: 25.4555px;
    line-height: 31px;
  }
  .about .doc-name h4 {
    font-size: 22px;
    /* font-family: 'Conv_Mark Simonson - Proxima Nova Bold'; */
    font-weight: 700;
  }
  .about .timeline {
    margin: 90px auto 40px;
  }
  .about .timeline li:nth-of-type(1n+5) {
    display: none;
  }
  .about .timeline li h6 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .about .timeline li a.active h6 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

/* .about .timeline li a.active p{
  color: #005BB7;
  font-size: 18px;
} */
  .purple-btn {
    font-size: 22px;
    padding: 14px 112px 13px;
  }
  a.purple-btn {
    font-size: 22px;
    padding: 14px 112px 13px;
  }
  a.watch {
    font-size: 14px;
    background-size: 32px auto;
    padding: 7px 0 7px 0;
  }
  a.watch img {
    width: 32px;
  }
  .learn-more {
    font-size: 16px;
  }
  a.learn-more {
    font-size: 16px;
  }
  footer {
    padding: 0 0 0;
  }
  footer .about-us {
    padding: 28px 34px;
    margin: 0 60px 40px;
  }
  footer .about-us p {
    font-size: 14px;
    line-height: 18px;
  }
  footer .about-us .learn-more-about {
    font-size: 14px;
  }
  footer .mailus-form .btn.submit-btn {
    margin-left: 20px;
  }
  footer .mail-us {
    margin-bottom: 40px;
  }
  footer .footer-bottom {
    padding: 26px 0 28px;
  }
  footer .footer-bottom p {
    font-size: 13px;
    margin-bottom: 2px;
    text-align: center;
  }
  .video-block{
    margin-bottom: 48px;
    padding-top: 0;
  }
  .video-block h2 {
    margin-bottom: 45px;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 40px 0;
  }
  .intelligent h2 {
    margin-bottom: 12px;
  }
  .intelligent .sub-heading{
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 44px;
  }
  .intelligent .int-content-wrap h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;

  }
  .intelligent .int-content-wrap p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 40px;
  }
  .health {
    padding: 60px 0 59px;
  }
  .health h2 {
    margin-bottom: 20px;
  }
  .intelligent .int-content-wrap .contentCol {
    padding: 0 10px;
}
}

@media screen and (max-height: 940px) and (max-width: 580px) {
  .banner .big-text {
    position: absolute;
    width: 80vw;
    height: 150px;
    top: 50%;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
  }
  .banner .small-text {
    position: absolute;
    width: 90vw;
    height: 120px;
    bottom: 15px;
    top: 70%;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
  }
  .banner .read-more img {
    margin-top: 10px;
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-height: 770px) {
  .banner .big-text {
    position: absolute;
    width: 80vw;
    height: 150px;
    top: 48%;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
  }
  .banner .small-text {
    position: absolute;
    width: 90vw;
    height: 120px;
    bottom: 15px;
    top: 67%;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
  .banner .read-more img {
    margin-top: 10px;
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-height: 600px) {
  .banner .big-text {
    position: absolute;
    width: 80vw;
    height: 150px;
    top: 37% !important;
    font-weight: 700;
    font-size: 28px !important;
    line-height: 38px !important;
  }
  .banner .small-text {
    position: absolute;
    width: 90vw;
    height: 120px;
    bottom: 15px;
    top: 70% !important;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 18px !important;
  }
  .banner .read-more {
    position: absolute;
    width: 96px;
    height: 30px;
    left: 27px !important;
    bottom: 20px !important;
  }
  .banner .read-more img {
    margin-top: 10px;
    width: 21px;
    height: 21px;
  }
}


@media screen and (min-width: 767px) {
  #menu-contatct-us {
    display: none;
  }
  .intelligent .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets{
    display: flex;
  }
  .intelligent .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    flex-basis: 0;
    flex-grow: 1;
    border-radius: 0;
    height: 4px;
  }
  
  .intelligent .right-information .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets,
  .intelligent .symptoms .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets,
  .intelligent .intake .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets,
  .intelligent .synopsis .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets{
    top: 0;
    bottom: auto;
  }
  .buttonWrapperfold2 {
    height: 55px;
 }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding: 12px 15px 12px;
  }
  .navbar-light .navbar-toggler{
    right: 28px;
    top: 15px;
  }
  .navbar-toggler span {
    height: 2px;
    width: 20px;
    margin-top: 4px;
    margin-bottom: 4px;
    position: relative;
    left: 0;
  }
  .schedule-container #schedule-demo-btn {
    padding-top: 12px;
  }
  .navbar-collapse {
    /* left: -15px !important; */
  }
    
  
  .navbar-toggler span:nth-child(2) {
    width: 15px;
    left: 5px;
  }
  .navbar .megamenu ul li img{
    height: 32px;
  }
  .navbar .dropdown-menu li span{
    width:70px;
  }
  .navbar-light .navbar-nav .nav-link{
    /* font-family: 'Conv_ProximaNova-Regular'; */
  }
  .navbar-brand img {
      width: 150px;
  }
  .navbar .megamenu ul li {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
  header a.contact-us-btn {
    display: none;
  }
  #menu-contatct-us #contactUsBtn {
    display: block;
    position: static;
  }
  #contactUsBtn {
    width: 81px;
    height: 32px;
    border-radius: 23.5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 12px 0px 16px;
    margin-right: 60px;
  }
  .spacer {
    height: 100px;
  }
  .video-block h2 {
    margin: 0 0 100px 0;
  }
  #schedule-demo-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    height: 36px;
    width: 70vw;
  }
  #menu-contatct-us #forpatientBtn {
    display: block;
    position: static;
  }
  .banner {
    padding: 0;
  }
  .banner .big-text {
    position: absolute;
    width: 80vw;
    height: 150px;
    left: 15px;
    top: 63%;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
  }
  
  .banner .small-text {
    position: absolute;
    width: 90vw;
    height: 120px;
    bottom: 15px;
    top: 80%;
    left: 15px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
  }

.banner .read-more {
  position: absolute;
  width: 96px;
  height: 30px;
  left: 40px;
  bottom: 40px;
}

.banner .read-more img {
  margin-top: 10px;
  width: 32px;
  height: 32px;
}
  .banner .banner-wrap {
    margin: 0px 0 20px;
    position: static;
  }
  .banner .banner-content h3 {
    line-height: 30px;
    text-align: center;
    margin-bottom: 12px;
  }
  .banner .banner-content p {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 24px;
    text-align: center;
    font-weight: 500;
  }
  .banner .banner-content a.see-plan {
    font-size: 16px;
    width: 240px;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px 10px 32px;
    display: block;
    margin: 0 auto;
    text-align: center;
    background: #005bb7 url(../../img/play-button-arrowhead.png) no-repeat 15px center;
    border-radius: 23px;
    background-size: 17px auto;
  }
  .banner .banner-content a.see-plan img {
    display: none;
  }

  .healthcare a.blue-btn{
    font-size: 16px;
    width: 240px;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px 10px 20px;
    display: block;
    margin: 0 auto;
    text-align: center;
    background: #2d3e4f url(../../img/play-button-arrowhead.png) no-repeat 15px center;
    border-radius: 23px;
    background-size: 17px auto;
    
  }
  .healthcare a.blue-btn img {
    margin-right: 12px;
    height: 20px;
    width: auto;
    display: inline-block;
    vertical-align: sub;
  }


  .trusted-by {
    padding: 30px 0 22px;
  }
  .trusted-by .trusted-by-text  {
    font-size: 14px;
  }
  .trusted-by ul li {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    margin-bottom: 14px;
  }
  .trusted-by ul li img{
    width: 84px;
  }
  .trusted-by ul li:nth-of-type(3) {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
  .outcome {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .outcome .marginBottom {
    padding-bottom: 0px;
  }
  .outcome .card {
    padding: 40px;
    margin: 0 40px 40px 40px;
    height: 350px;
  }
  .outcome h3 {
    font-size: 17px;
    line-height: 22px;
    margin:0 auto 29px;
    max-width: 313px;
    text-align: center;
  }
  .outcome img {
    width: 90px;
  }
  .outcome .number {
    font-weight: 600;
    font-size: 32px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 8px;
    display: block;
  }
  .outcome .card p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
  .outcome .card .understatement {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
  .outcome .card img {
    width: 75px;
    height: auto;
  }
  .outcome p br{
    display: none;
  }
  .outcome .col-md-4 {
    margin-bottom: 32px;
  }
  .timeline {
    margin-top: 20px;
    padding: 6% 20px;
    background-size: 70% auto;
  }
  .timeline h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .timeline .steps-container .step {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 20px;
  }
  .timeline .steps-container ul {
    margin-top: 30px;
  }
  .timeline .steps-container {
    margin-top: 45px;
    padding: 0;
  }
  .timeline .steps-container li {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
  }
  .timeline .steps-container div {
    height: auto;
    min-height: 200px;
    padding: 25px 20px;
    text-align: left;
    margin-bottom: 0px;
  }
  .healthcare {
    padding: 34px 0 34px;
    background-size: 50% 102px;
  }
  .healthcare h2 {
    margin-bottom: 74px;
  }
  .healthcare img{
    width: 74px;
    margin: 0 auto;
    display: block;
  }
  .blue-btn {
    font-size: 20px;
    padding: 12px 75px;
  }
  a.blue-btn {
    font-size: 20px;
    padding: 12px 75px;
  }
  .fts-52 {
    font-size: 24px;
  }
  .practice {
    padding: 44px 0 0px;
  }
  .practice .heading-content {
    margin-bottom: 30px;
  }
  .practice .heading-content h2 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  .practice .heading-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  .practice .practice-content ul {
    padding: 0;
    display: inline;
    
  }
  .practice .practice-content ul .mobile-image {
    margin: 10px;
  }
  .practice .practice-content .col-sm-12 {
    padding: 0;
  }
  .practice .practice-content ul li a.active:after{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding: 15px 0;
  }
  .practice .practice-content ul li {
    width: 100%;
    height: 37px;
    margin-bottom: 10px;
    background: #fff;
    text-align: left;
    padding: 2px;
    border-radius: 0;
    box-shadow: none;
  }
  .practice .practice-content ul li .category-icon {
    width: 15px;
    height: 18px;
    display: inline;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .practice .practice-content ul li .plus-minus {
    width: 18px;
    height: 18px;
    display: inline;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }
  .practice .practice-content ul li a {
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 36px;
    color: #2D3E50;
    padding-left: 32px;
    padding-top: 0;
  }
  .practice .practice-content ul li a.active {
    background-color: #FCF1FF;
  }
  .fts-42 {
    font-size: 24px;
  }
  .about {
    padding: 48px 0 50px;
    background: transparent;
  }
  .purple-btn {
    font-size: 20px;
    padding: 12px 70px;
  }
  a.purple-btn {
    font-size: 20px;
    padding: 12px 90px;
  }
  .intelligent .synopsis .swiper-container,
  .intelligent .symptoms .swiper-container,
  .intelligent .right-information .swiper-container,
  .intelligent .intake .swiper-container{
    background-color: transparent;
  }
  footer{
    /* background-color: transparent; */
  }
  footer .about-us {
    padding: 19px 24px;
    margin: 0 30px 22px;
  }
  footer .about-us p {
    font-size: 12px;
  }
  footer .about-us .learn-more-about {
    font-size: 12px;
    font-weight: bold;
  }
  footer .mail-us {
    margin-bottom: 20px;
  }
  footer .mail-us p.footer-font {
    font-size: 12px;
  }
  footer .mail-us p strong {
    margin-bottom: 16px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 0.3px;
  }
  footer .mailus-form input[type="text"] {
    font-size: 12px;
    width: 231px;
    text-indent: 0;
  }
  footer .mailus-form .btn.submit-btn {
    margin-left: 0;
    padding: 8px 20px;
  }
  .video-block h2{
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 24px;
  }
  .video-block {
    margin-bottom: 33px;
  }
  .intelligent .sub-heading {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    margin-bottom: 24px;
 
  }
  .intelligent .swiper-container{
    padding-bottom: 30px;
  }
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom:0;
  }
  .health {
      padding: 46px 0 54px;
  }
  footer .mail-us strong{
    display: block;
  }
  .video-block h2{
    padding: 0 30px;
  }
  .intelligent .int-content-wrap .contentCol {
    padding: 0 ;
  }
}
@media (max-width: 576px){
  /* header{padding: 0 15px;} */
  .practice .heading-content p {
    font-size: 14px;
    line-height: 18px;
  }
}