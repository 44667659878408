.deletion-section {
    margin: 25px 20px 50px 20px;
    .line-hr {
        margin-top: 10px;
        margin-bottom: 40px;
        border: 1px solid #0b151f;
    }
}
ul>li {
    padding: 5px;
}
ul {
    margin-bottom: 75px;
}