@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova-Regular.eot');
  src: local('☺'), url('../../fonts/ProximaNova-Regular.woff') format('woff'), url('../../fonts/ProximaNova-Regular.ttf') format('truetype'), url('../../fonts/ProximaNova-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Mark Simonson - Proxima Nova Semibold.eot');
	src: local('☺'), url('../../fonts/Mark Simonson - Proxima Nova Semibold.woff') format('woff'), url('../../fonts/Mark Simonson - Proxima Nova Semibold.ttf') format('truetype'), url('../../fonts/Mark Simonson - Proxima Nova Semibold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Mark Simonson - Proxima Nova Medium.eot');
	src: local('☺'), url('../../fonts/Mark Simonson - Proxima Nova Medium.woff') format('woff'), url('../../fonts/Mark Simonson - Proxima Nova Medium.ttf') format('truetype'), url('../../fonts/Mark Simonson - Proxima Nova Medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Mark Simonson - Proxima Nova Bold.eot');
	src: local('☺'), url('../../fonts/Mark Simonson - Proxima Nova Bold.woff') format('woff'), url('../../fonts/Mark Simonson - Proxima Nova Bold.ttf') format('truetype'), url('../../fonts/Mark Simonson - Proxima Nova Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Mark Simonson - Proxima Nova Thin.eot');
	src: local('☺'), url('../../fonts/Mark Simonson - Proxima Nova Thin.woff') format('woff'), url('../../fonts/Mark Simonson - Proxima Nova Thin.ttf') format('truetype'), url('../../fonts/Mark Simonson - Proxima Nova Thin.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}


body,html{
  overflow-x: hidden;
}

/* footer */
img {
  max-width: 100%;
}

.fts-52 {
  font-size: 52px;
  line-height: 46px;
}

.fts-42 {
  font-size: 42px;
  line-height: 44px;
}

.blue-btn {
  font-size: 24px;
  line-height: 18px;
  background: #2d3e50;
  padding: 18px 150px;
  color: #fff;
  display: inline-block;
  border-radius: 26px;
  height: 100%
}

.blue-btn:hover {
  color: #fff;
  text-decoration: none;
}

.blue-btn:focus {
  color: #fff;
  text-decoration: none;
}

a.blue-btn {
  font-size: 24px;
  line-height: 18px;
  background: #2d3e50;
  padding: 18px 150px;
  color: #fff;
  display: inline-block;
  border-radius: 26px;
}

a.blue-btn:hover {
  color: #fff;
  text-decoration: none;
}

a.blue-btn:focus {
  color: #fff;
  text-decoration: none;
}

.purple-btn {
  font-size: 24px;
  line-height: 18px;
  background: #5e3461;
  padding: 18px 150px;
  color: #fff;
  display: inline-block;
  border-radius: 26px;
}

.purple-btn:hover {
  color: #fff;
  text-decoration: none;
}

.purple-btn:focus {
  color: #fff;
  text-decoration: none;
}

a.purple-btn {
  font-size: 24px;
  line-height: 18px;
  background: #5e3461;
  padding: 18px 150px;
  color: #fff;
  display: inline-block;
  border-radius: 26px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 500;
}

a.purple-btn:hover {
  color: #fff;
  text-decoration: none;
}

a.purple-btn:focus {
  color: #fff;
  text-decoration: none;
}

a.watch {
  font-size: 16px;
  padding: 20px 0 20px 0;
  display: inline-block;
}

a.watch:focus {
  color: #5e3461;
}

a.watch:hover {
  color: #5e3461;
}

.navbar-toggler:focus {
  outline: 0;
}

.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background-color: #444;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(2) {
  width: 20px;
  left: 5px;
}

.navbar-toggler span:nth-child(1) {
  -webkit-transition: transform .35s ease-in-out;
  -webkit-transition: -webkit-transform .35s ease-in-out;
  transition: -webkit-transform .35s ease-in-out;
  transition: transform .35s ease-in-out;
  transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
}

.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform .35s ease-in-out;
  -webkit-transition: -webkit-transform .35s ease-in-out;
  transition: -webkit-transform .35s ease-in-out;
  transition: transform .35s ease-in-out;
  transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

.navbar {
  padding: 23px 48px 17px;
}

.navbar .dropdown-menu .dropWrap{
  -webkit-box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.15);
          background: #fff;
}

.navbar .megamenu {
  padding-top: 0;
}
@media screen and (min-width: 991px) {
  .navbar .dropdown.menu-large {
    position: relative;
  }
  .navbar .dropdown-menu.megamenu {
    top: 36px;
    left: -80px;
    width: 540px;
  }
  .navbar .dropdown-menu .dropWrap {
    width: 560px;
    border-radius: 8px;
  }
  .navbar .dropdown-menu .dropWrapAOM {
    width: 560px !important;
  }
  span.up-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    content: '';
    position: absolute;
    left: 150px;
    box-shadow: 0 -22px 21px 0 rgba(0, 0, 0, 0.15);
    top: 16px;
  }
}

.navbar .megamenu *:before{
  display: none;
}
.navbar .megamenu ul {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar .megamenu ul li {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  height: 50px;
  margin: 0;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
}
.navbar .megamenu ul li .nav-link {
  font-weight: normal;
  padding: 0;
  width: 100%;
}
.navbar .megamenu ul li:hover{
  background-color: #ecf4fd;
}
.navbar .megamenu ul li .nav-link>span {
  height: 37px;
  width: 37px;
  display: inline-block;
  padding: 5px;
  border-radius: 100%;
  background-color: #ebf5ff;
  margin-left: 12px;
  margin-right: 28px;
  text-align: center;
}
.navbar .megamenu ul li .nav-link>sub {
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #666;
}
.megamenu ul.care-ul li .nav-link{
  font-weight: 500;
  line-height: 20px;
  font-size: 18px;
  /* line-height: 15px; */
  padding-left: 16px;
}
.megamenu ul.care-ul li .nav-link.header-cp-active{
  font-weight: 700;
}
.megamenu ul.care-ul li {
  height: 60px;
}

.navbar .megamenu ul li .nav-link>span img{
  max-height: 100%;
}
.dropdown-menu.megamenu.hidden {
  display: none !important;
}
.navbar-light .navbar-nav .nav-link{
  font-weight: 600;
}
header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  max-width: 100vw;
}
@keyframes slide-down {
	0% {
		opacity: 0;
		transform: translateY(-100%);
	}

	100% {
		opacity: 0.9;
		transform: translateY(0);
	}

}


header .nav-item {
  margin-right: 26px;
}

header .navbar-nav .nav-link {
  font-size: 18px;
}

header a.contact-us-btn {
  background: transparent;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 20px;
  color: #fff;
  padding: 8px 25px 7px 25px;
  outline: none;
  font-weight: 600;
  border: 2px solid #ffffff;
}
header a.contact-us-btn:hover,
header a.contact-us-btn:focus{
  color: #fff;
  outline: none;
  box-shadow: none;
}

.full-width {
  width: 100vw;
}
.schedule-container {
  /* position: absolute;
  bottom: 30px;
  left: 5vw; */
}

.spacer {
  height: 115px;
}

#schedule-demo-btn {
  border-radius: 45px;
  width: 476px;
  height: 54px;
  padding: 8px 25px 7px 25px;
  outline: none;
  font-weight: 600;
  background-color: #005BB7;
  color: #ffffff;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
}

#contactUsBtn {
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 8px 25px 7px 25px;
  outline: none;
  font-weight: 600;
  background-color: #005BB7;
  color: #ffffff;
  margin-right: 20px;
}

header a.schedule-demo-btn {

}

header a.contact-us-btn-bdr{
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 20px;
  color: #2d3e50;
  padding: 8px 25px 7px 25px;
  outline: none;
  font-weight: 600;
  border-radius: 23.5px;
  border: solid 2px #2d3e50;
  background-color: transparent;
  top: 22px !important;
}
header a.contact-us-btn-bdr:hover,
header a.contact-us-btn-bdr:focus{
  color: #2d3e50;
  outline: none;
  box-shadow: none;
}
.header::after {
  bottom: 0;
  box-shadow: 0 14px 14px -14px rgba(0, 0, 0, 0.11);
  /* content: ''; */
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.header.is-active {
  background: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  animation: slide-down 0.5s;
  left: 0;
  right: 0;
  color: #2d3e50;
}

.header.is-active::after {
  opacity: 1;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  will-change: transform;
}

.sticky--top {
  top: 0;
  right: 0;
}

.learn-more {
  font-size: 20px;
  line-height: 22px;
  color: #2D3E4F;
  display: inline-block;
  background: url(../../img/arrow-web-purple.svg) no-repeat center right;
  background-size: 22px auto;
  padding-right: 30px;
}

a.learn-more {
  font-size: 20px;
  line-height: 22px;
  color: #2D3E4F;
  display: inline-block;
  /* /background: url(../../img/arrow-web-purple.png) no-repeat center right; */
  background: url(../../img/arrow-web-purple.svg) no-repeat center right;
  background-size: 22px auto;
  padding-right: 30px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 500;
}

a.learn-more-aom {
  font-size: 20px;
  line-height: 22px;
  display: inline-block;
  /* /background: url(../../img/arrow-web-purple.png) no-repeat center right; */
  background: url(../../img/arrow-black.svg) no-repeat center right;
  background-size: 22px auto;
  padding-right: 30px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 500;
}

body {
  font-family: 'Proxima Nova';
}

.img-fluid {
  max-width: 100%;
}

.navbar-brand img {
  width:194px;
}

.banner {
  padding: 0;
  position: relative;
  height: 100vh;
  background-color: #FCF1FF;
}

.banner .big-text {
  position: absolute;
  width: 579px;
  height: 222px;
  left: 47px;
  top: 45%;
  font-weight: 700;
  font-size: 62.6154px;
  line-height: 74px;
  color: #FFFFFF;
  z-index: 15;
}

.banner .small-text {
  position: absolute;
  width: 572px;
  height: 90px;
  left: 47px;
  top: 72%;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #FFFFFF;
  z-index: 15;
}

.banner .read-more {
  position: absolute;
  width: 96px;
  height: 30px;
  left: 50%;
  margin-left: -48px;
  bottom: 70px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  z-index: 15;
}

.banner .read-more img {
  margin-top: 10px;
  width: 32px;
  height: 32px;
}

.banner video {
  max-width: 100vw;
  max-height: 100vh;
}

.banner .banner-wrap {
  position: absolute;
  top: 74px;
  left: 0;
  right: 0;
  margin: auto;
}

.banner .banner-content {
  margin-left: 36px;
}

.banner .banner-content h3 {
  margin-bottom: 30px;
  position: relative;
  font-weight: 500;
  color: #1668ba;
}

.banner .banner-content h3:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1668ba;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 7px;
  margin-right: 10px;
}

.banner .banner-content h3:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1668ba;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
  margin-left: 4px;
}

.banner .banner-content p {
  font-size: 44px;
  margin-bottom: 39px;
  line-height: 52px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 500;
  max-width: 585px;
}

.banner .banner-content a.see-plan {
  color: #19222c;
  font-size: 28px;
  text-decoration: underline;
  background-size: 48px auto;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  padding: 10px 35px 11px 85px;
  display: inline-block;
  text-align: center;
  background: #005bb7 url(../../img/play-button-arrowhead.png) no-repeat 35px center;
  border-radius: 31px;
  background-size: 28px auto;
}
.banner .banner-content a.see-plan img {
  display: none;
}

.trusted-by {
  background: #EBF5FF;
  padding: 48px 0 47px;
}

.trusted-by .trusted-by-text {
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 1.95px;
  text-transform: uppercase;
  color: #2D3E4F;
}

.trusted-by ul {
  padding: 0;
  margin: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.trusted-by ul li {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
      /* trusted by section new logo width */
    max-width: 136px;
    width: 100%;
}

.trusted-by h4 {
  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 2px;
  margin-bottom: 28px;
  font-weight: normal;
}

.outcome {
  padding-top: 82px;
  background-color: #FCF1FF;
}

.outcome h3 {
  font-size: 36px;
  line-height: 44px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 500;
  margin-bottom: 57px;
}

.outcome .p-0 {
  padding-left: 0;
  padding-right: 0;
}

/* .outcome .center-text {
  display: flex; 
  justify-content: center;
  align-items: center;
} */

.outcome .number {
  font-size: 2.8rem;
  line-height: 60px;
  font-weight: 500;
}

.outcome .number span {
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Thin'; */
  font-weight:300;
}

.outcome img {
  margin-bottom: 22px;
  width: 200px;
}

.outcome p {
  font-size: 22px;
  margin: 0 auto;
  line-height: 26px;
}
.outcome .marginBottom {
  padding-bottom: 100px;
}


.timeline {
  display: block;
  background-color: #ffffff;
  position: relative;

  padding: 70px;
  margin-top: 100px;
  background-image: url("../../img/rectangle1.png");
  background-repeat: no-repeat;
  background-size: 60% auto;
  background-position: top left;
}

.timeline .steps-container {
  margin-top: 165px;
  padding: 0 100px;
}
.timeline .steps-container div {
  height: auto;
  min-height: 200px;
  padding: 25px 40px;
  text-align: left;
  margin-bottom: 20px;
}
.timeline .steps-container .step {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #3572B9;
  border: 2px solid #3572B9;
  border-radius: 50px;
  padding: 15px 25px;
}
.timeline .steps-container ul {
  margin-top: 45px;
  padding-left: 15px;
  list-style: none;
}

.timeline .steps-container li {
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #2D3E4F;
}

.timeline .steps-container li::before {
  content: '•';
  color: #2171C0;
  display: inline-block;
  font-size: 25px;
  padding-right: 5px;
  margin-left: -0.93rem;
}

.timeline h3 {
  font-weight: 400;
  font-size: 42px;
  line-height: 60px;
  color: #2D3E4F;
}

.healthcare {
  padding: 83px 0 100px;
  background-image: url("../../img/rectangle.png");
  background-repeat: no-repeat;
  background-size: 50% auto;
  background-position: top left;
}
.healthcare a.blue-btn{
  color: #19222c;
  font-size: 28px;
  text-decoration: underline;
  background-size: 48px auto;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  padding: 17px 35px 15px 85px;
  display: inline-block;
  text-align: center;
  background: #2d3e4f url(../../img/play-button-arrowhead.png) no-repeat 35px center;
  border-radius: 31px;
  background-size: 28px auto;
  height:100%
}


.healthcare a.blue-btn img {
  display: none;
}

.healthcare h2 {
  text-align: center;
  margin-bottom: 163px;
}
.healthcare h4{
  line-height: 22px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Semibold';   */
  font-weight:600;
  margin-bottom: 12px;
}
.healthcare p {
  margin-bottom: 60px;
}
.healthcare p strong{
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Bold'; */
  font-weight: normal;
}
.practice {
  background: #ffffff;
  padding: 110px 0 0;
}

.practice .heading-content {
  margin-bottom: 20px;
}

.practice .heading-content h2 {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 42px;
  line-height: 51px;
  text-align: center;
  color: #2D3E4F;
}
.practice .practice-content .col-lg-4 {
  padding-right: 0;
}

.practice .heading-content p {
  max-width: 1057px;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  text-align: center;

  color: #2D3E4F;
  margin: 0 auto;
}

.practice .heading-content a.contact-us-link {
  text-decoration: underline;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Bold';   */
  font-weight: 700;
}
.practice .practice-content ul {
  margin-top: 65px;
  list-style: none;
}
.practice .practice-content ul li {
  margin-bottom: 30px;
  padding-left: 50px;
  position: relative;
  cursor: pointer;
}
/* Removed as the thoracic surgery block was bigger than the rest */
/* .practice .practice-content ul li:last-child{
  margin-bottom: 0;
} */
.practice .practice-content ul li .category-icon {
  height: 30px;
  position: absolute;
  left: 2px;
  top: 10px;
  z-index: 2;
}

.practice .practice-content ul li a {
  font-size: 20px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  padding-top: 15px;
  display: inline-block;
}

.practice .practice-content ul li a.active:after{
  border-radius: 30px 0 0 30px;
  content: "";
  position: absolute;
  left: -70px;
  top: -5px;
  width: 650px;
  padding: 30px 0;
  z-index: -1;
  background-color: #FCF1FF;
}

.practice .tabChangingImage{
  position: relative;
}
.practice .tabChangingImage img.bgimg{
  /* position: absolute; */
  width: 100%;
}
.practice .tabChangingImage .contentWrap{
  /* position: absolute; */
  top: 0;
  left: 0;
  bottom:0;
  right:0;
}
.practice .tabChangingImage .contentWrap .shadow{
  box-shadow: 0 2px 18px 0 rgba(87, 111, 136, 0.19);
  border-radius: 8px;
  overflow: hidden;
}
.practice .tabChangingImage .imageContent{ 
  display: flex;
  justify-content: center;
  height: 442px;
}
.practice .tabChangingImage .imageContent .imgcol{
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  padding: 42px 38px 16px;
  padding: 16px 38px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pinkBackground {
  background-color: #fcf1ff;
}
.blueBackground {
  background-color: #ebf5ff;
}
.peachBackground {
  background-color: #ffe7e5;
}
.orangeBackground {
  background-color: #ffe9de;
}



.practice .tabChangingImage .imageContent .imgcol img{
  max-width: 100%;
  max-height: 100%;
}
.practice .tabcontent{
  position: relative;
  z-index: 1;
  background: #FCF1FF;
  border-radius: 60px;
  height: 100%;
}
.practice .tabcontent a.carepathway{
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  width:426px;
  height: 38px;
  border-radius: 19px;
  background-color: #2d3e4f;
  display: block;
  text-align: center;
  padding: 7px 0;
  margin: 10px 0 0;
  text-decoration: none;
}
.practice .tabcontent a.learnMoreTo{
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2; 
  letter-spacing: normal;
  background-color:transparent;
  width: 426px;
  height: 38px;
  border-radius: 19px;
  color: #2d3e4f;
  border: #2d3e4f solid 2px;
  display: block;
  text-align: center;
  padding: 7px 0;
  margin: 10px 0 0;
  text-decoration: none;
}
.practice .tabcontent .tabContentHeader{
  /* padding: 21px 0 22px; */
  padding: 10px 0 10px;
  background: #fff;
  text-align: center;
  height: 130px;
}
.practice .tabcontent .tabContentHeader h2{
  font-size: 24px;
  margin-bottom: 8px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 600;

}
.practice .tabcontent .tabContentHeader p{
  font-size: 18px;
  max-width: 633px;
  margin: 0 auto;
  margin-bottom: 0;
}
.blocked{
  background: #e4d5e8;
  /* color: #fff !important; */
  border-radius: 10px;
  padding: 1px 6px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 500;
}
.about {
  padding: 96px 0 110px;
  background: #EBF5FF;
  border-radius: 140px 140px 0 0;
  margin: 5% 5% -7% 5%;
}

.about * {
  color: #2D3E4F;
}

.about h2 {
  margin-bottom: 68px;
  color: #2D3E4F;
  font-weight: 500;
}
.doc-name h4{
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Bold'; */
  font-weight: 700;
}
.about .timeline {
  background-image: none;
  background-color: #EBF5FF;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 90px auto 100px;
  width: 88%;
  position: relative;
}

.about .timeline:after {
  content: "";
  width: 100%;
  height: 6px;
  background: #B1C5DA;
  position: absolute;
  left: 0;
  top: -20px;
  border-radius: 4px;
}

.about .timeline li {
  cursor: pointer;
  padding: 0 0;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  position: relative;
}

.about .timeline li h6 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  color: #667E97;
}

.about .timeline li p {
  color: #667E97;
  font-size: 16px;
}

.about .timeline li a.active:after {
  content: "";
  width: 100%;
  height: 7px;
  background: #005BB7;
  position: absolute;
  left: 0;
  top: -21px;
  z-index: 1;
  border-radius: 4px;
}

.about .timeline li a{
  transition: 0.5s ease-in-out;
  transition-delay: 2s;
}

.about .timeline li a.active:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  top: -21px;
  left:45%; /* added for center alignment */
  background: url("../../img/down-arrow.png") no-repeat center;
  background-size: 22px auto;
}

.about .timeline li a.active h6 {
  margin-bottom: 0;
  font-size: 22px;
  color: #005BB7;
}

.about .timeline li a.active p{
  color: #005BB7;
  font-size: 16px;
}

.about .swiper-slide {
  border-radius: 36px;
  background-color: #E2F0FF;
}

.about .swiper-slide .slide-content {
  padding: 34px 38px 44px;
  text-align: center;
  height: 350px;
}

.about .swiper-slide .slide-content .doc-img {
  width: 87px;
  margin: 0 auto 6px;
  display: block;
}

.about .swiper-slide .slide-content h6 {
  font-weight: bold;
  margin-bottom: 2px;
  color: #2D3E4F;
}

.about .swiper-slide .slide-content p {
  font-size: 14px;
  color: #2D3E4F;
}

.about .swiper-slide .slide-content .slide-btn a {
  font-size: 12px;
  text-decoration: underline;
  color: #2D3E4F;
  position: relative;
}

.about .swiper-slide .slide-content .slide-btn a.slider-learn-more {
  /* margin-right: 42px; */
}

.about .swiper-slide .slide-content .slide-btn a.slider-learn-more:after {
  content: ">";
  margin-left: 3px;
  position: absolute;
  bottom: -3px;
  right: -10px;
  text-decoration: none;
}

.about .swiper-slide .slide-content .slide-btn a.slider-watch-video:after {
  content: "";
  position: absolute;
  bottom: 3px;
  right: -12px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-left: 8px solid #2D3E4F;
  border-bottom: 4px solid transparent;
}

.about .swiper-wrapper {
  padding-bottom: 28px;
}
.about .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic{
    transform: translateX(0);
    white-space: nowrap;
    margin: 0 auto;
}
.about .swiper-pagination {
  position: static;
}

.about .swiper-container {
  margin-bottom: 38px;
}

.swiper-pagination-bullet {
  background: #c3c3c3;
}

.swiper-pagination-bullet-active {
  background: #5a2966;
}

.video-block {
  margin: 60px 0 100px 0;
  position: relative;
}

.video-block:after {
  content: "";
  background: #FAF5FC;
  width: 100%;
  height: 70%;
  position: absolute;
  top: -60px;
  z-index: -1;
}

.video-block h2 {
  text-transform: uppercase;
  margin: 80px 0;
  line-height: 28px;
}

.video-block h2 span {
  /* font-weight: bold; */
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Bold'; */
  font-weight: 700;
  display: inline-block;
  margin-right: 15px;
}
.intelligent .swiper-slide{
  text-align: center;
  /* padding-top: 40px; */
}
.intelligent .swiper-button-prev{
  left: 0;
}
.intelligent .swiper-button-next{
  right: 0;
}
.intelligent .swiper-slide img{
  width: 100%;
}
.intelligent .sub-heading{
  max-width: 709px;
  margin:0 auto 60px;
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: #2D3E4F;
}
.intelligent h2{
  margin-bottom: 16px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
  font-weight: 600;
}
.intelligent .int-content-wrap h4{
  font-size: 26px;
  line-height: 30px;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Semibold'; */
  font-weight:600;
}
.intelligent .int-content-wrap p{
  line-height: 21px;
}
.intelligent .int-content-wrap .contentCol{
  padding: 0 80px;
}
.intelligent .swiper-button-prev:after,
.intelligent .swiper-button-next:after{
  font-size: 18px;
}
.intelligent .right-information .swiper-container{
  background-color: #ebf5ff;
}
.intelligent .right-information .swiper-button-prev:after, 
.intelligent .right-information .swiper-button-next:after{
  color: #2d3e4f;
}
.intelligent .symptoms .swiper-container{
  background-color: #ffe9de;
}
.intelligent .symptoms .swiper-button-prev:after,
.intelligent .symptoms .swiper-button-next:after{
  color: #b2690c;
}
.intelligent .intake .swiper-container{
  background-color: #fcf1ff;
}
.intelligent .intake .swiper-button-prev:after,
.intelligent .intake .swiper-button-next:after{
  color: #5a2a66;
}
.intelligent .synopsis .swiper-container{
  background-color: #f4fbeb;
}
.intelligent .synopsis .swiper-button-prev:after,
.intelligent .synopsis .swiper-button-next:after{
  color: #527524;
}


.intelligent .right-information .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  background: #d8d8d8;
}
.intelligent .symptoms .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  background: #d8d8d8;
}
.intelligent .intake .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  background: #d8d8d8;
}
.intelligent .synopsis .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  background: #d8d8d8;
}

.intelligent .right-information .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: #2a3e4f;
}
.intelligent .symptoms .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: #2a3e4f;
   /* #df8814; */
}
.intelligent .intake .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: #5a2a66;
}
.intelligent .synopsis .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: #2a3e4f;
  /* #527524; */
}
.swiper-button-prev, .swiper-button-next{
  color: #aaa;
}
.health{
  background: #fcf1ff;
  padding: 86px 0 100px; 
}
.health h2{
  margin-bottom: 44px;
  color: #5e3461;
  font-weight: 500;
}

footer {
  background: url("../../img/footer.png") no-repeat center;
  background-size: cover;
  position: relative;
  padding: 0 0 60px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 100;
}
footer .about-us {
  max-width: 980px;
  background: #fff;
  border-radius: 16px;
  padding: 36px 69px;
  margin: 0 auto 40px;
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.17), 0 2px 20px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.17), 0 2px 20px 0 rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.17), 0 2px 20px 0 rgba(0, 0, 0, 0.09);
  top: -50px;
  left: 0;
  right: 0;
  position: relative;
}

footer .about-us .learn-more-about {
  font-size: 18px;
  line-height: 11px;
  color: #1b2b36;
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Semibold'; */
  font-weight:600;
}
footer .about-us .learn-more-about img{
  margin-left: 10px;
}
footer .about-us p {
  color: #142e48;
  font-size: 19px;
  line-height: 26px;
}

footer .mail-us {
  margin-bottom: 58px;
}
footer .mail-us strong{
  /* font-family: 'Conv_Mark Simonson - Proxima Nova Bold'; */
  font-weight: 700;
}
footer .mail-us p {
  color: #fff;
  margin-bottom: 32px;
}

footer .mailus-form input[type="text"] {
  width: 424px;
  text-indent: 4px;
  border-radius: 7px;
}

footer .mailus-form .btn.submit-btn {
  background: #f7b500;
  color: #1e2b35;
  font-size: 14px;
  padding: 11px 49px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 14px;
  border-radius: 20px;
}
footer .mailus-form .btn.submit-btn-success {
  background:  #9ad154;;
  color: #1e2b35;
  font-size: 14px;
  padding: 11px 49px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 14px;
  border-radius: 20px;
}

footer .footer-bottom {
  border-top: 1px solid #656C72;
  padding: 26px 0 56px;
  margin: 0 25px;
}

footer .footer-bottom p {
  /* color: #808990; */
  color:#eeeeee;
  font-size: 16px;
}
footer .footer-bottom p a {
  /* color: #808990; */
  color: #eeeeee;
  font-size: 16px;
  padding-right: 15px;
  padding-left: 15px;
}
/*# sourceMappingURL=style.css.map */

.formHeaderBlue {
  background: #FAF5FC !important;
}
.button-group-info {
  display: inline-flex;
  margin: 0;
  margin-top: 40px;  
  justify-content: space-between;
  width: 100%;
}
.footer-about  p{
  margin-bottom: 0px;
}

@media screen and (max-width:1199px){
  .showList {
    display: block !important;
  }
  .hideList {
    display: none !important;
  }
}

@media screen and (max-width:600px){
  .carepathwayitem {
      display: flex;
  }
  .carepathwayitem sub {
      padding-left:12px;
  }
  a.learn-more-aom {
    font-size: 16px;
  }
}

@media screen and (max-width:1199px){
  .active-dropdown {
    font-weight: 700 !important;
 }
}

/* .underline {
  border-bottom: 2px solid #2d3e4f;
}
.underline :before {
  content: "";
    width: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    border-bottom: 2px solid #2d3e4f;
    visibility: hidden;
  } */

@media screen and (min-width: 767px){
  .practice-content {
    margin-top:36px;
  }
  .marginCustom30 {
    line-height: 30px !important;
  }
  footer .about-us p {
    font-size: 19px;
    line-height: 26px;
  }
 

}

@media screen and (min-width: 512px){

  footer .about-us p {
    font-size: 19px;
    line-height: 26px;
  }

}
@media screen and  (min-width: 1187px) {
.navbar-expand-lg .navbar-toggler {
    display: none;
}
}
.about-image {
   max-width: 764px;
   width: 100%;
}

.emailInput {
  
}

@media  screen and (min-width: 1200px){
  .header-active {
    border-bottom :2px solid #FFFFFF;
    border-radius: 1px;
  }
}

@media  screen and (min-width:991px){
.padLeftDeliver {
  padding-left: 105px;
}
}
.navbar-light .navbar-nav .nav-link:before{
  border-bottom: none;
}

.selectedAOM {
  font-weight: 700 !important;
}

@media screen and (max-width:991px){

.customRow {
    flex-direction: column-reverse;
}
}

.practice .practice-content ul li .active {
  font-weight: 700;
}
.padLeftNone {
  padding-left: 0px;
}
.padBottom {
  padding-bottom: 100px;
}
.downloadSection {
   height: 44px;
   background-color: #125496;
   width: 100%; 
   color: #ffffff;
   font-size: 18px;
   font-weight: normal;
   text-align: center;
   padding: 5px 0px;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: row;
   justify-content: center;
   position: relative;
   align-items: center;
} 

.downloadSection  .second-item {
  right: 0;
  position: absolute;
}

.downloadSection p {
  margin: 5px 0;
  overflow: auto;
}


.downloadSection u {
  font-weight: bold;
}
.topBanner {
  color: #ffffff;
  font-weight: bold;
}

.topBanner:hover {
  color: #89C4FF;
}
.hideHeaderBtn {
  width: 23px;
  height: 23px;
  cursor: pointer;
  /* position: absolute;
  right: 11%;
  top: 10px;
  cursor: pointer; */
}

.hideHeaderBtnWrapper { 
   position: absolute;
   right: 20px
}

@media screen and (max-width: 767px) {
  .downloadSection {
    font-size: 14px;
    text-align: left;
    height:47px;
  }
  .blockUserInfo {
    flex-direction: column-reverse;
  }
  /* .hideHeaderBtn {
    right: 5%;
    top: 10px
  } */
  
  .downloadSection  .downloadTxt {
    /* margin: 7px 0px 6px 24px; */
    overflow: auto;
    max-width: 240px;
    height: 34px;
    line-height: normal;
  }
}
