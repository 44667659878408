.aboutBanner{
    margin-bottom: 40px;
    padding: 20px;
    @media screen and (max-width: 991px) {
        margin-bottom: 41px;
    }
}
.docplantilte{
   padding-left: 10px;
}
.aboutText{
    padding: 54px 0;
    margin-bottom: 48px;
    background: url(/static/media/about-new-background.af44ad15.svg) no-repeat left 6%;
    background-size: 245px;
    
    //background-size: contain;
    @media screen and (min-width:992px){
        padding: 54px 130px;

    }
    @media screen and (max-width: 991px) {
        padding: 28px 130px;
        margin-bottom: 24px;
        background: url('../../img/about-new-background.svg') no-repeat left 0%;
        // background-size: contain;
    }
    @media screen and (max-width: 767px) {
        padding: 30px;
        margin-bottom: 44px;

        // background: url('../../img/about-new-background.svg') no-repeat left center;
        // background-size: contain;
    }
    h2{
        font-weight: 400;
        font-size: 50px;
        line-height: 40px;
        line-height: 40px;
        margin-bottom: 50px;
        font-weight: normal;
        @media screen and (max-width: 991px) {
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 16px;
        }
        @media screen and (max-width: 767px) {
            font-weight: 400;
            font-size: 36px;
            margin-bottom: 21px;
        }
        .docplantilte {
            font-weight: 600;
        }
    }
    p {
        font-size: 18px;
        line-height: 28px;
        @media screen and (max-width: 991px) {
            font-size: 14px;
            line-height: 20px;
            margin-top: 40px;
        }
        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
            margin-top: 32px;
        }
    }
}
.core{
    margin-bottom: 100px;
    padding: 0 110px;
    @media screen and (min-width: 992px) {
        padding: 0 110px;
    }
    @media screen and (max-width: 991px) {
        padding: 0 106px;
    }
    @media screen and (max-width: 767px){
        padding:0 10px;
    }
    h2{
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 44px;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Medium';
        font-weight: 500;
        @media screen and (max-width: 991px) {
            font-size: 26px;
            margin-bottom: 40px;
        }
        @media screen and (max-width: 767px) {
            font-size: 36px;
            margin-bottom: 40px;
        }
    }
    .content{
        img{
            margin-bottom: 24px;
            display: block;
            @media screen and (max-width: 991px) {
                width: 96px;
            }
            @media screen and (max-width: 767px) {
                width: 74px;
            }
        }
        h2{
            font-size: 32px;
            //line-height: 22px;
            line-height: 30px;
            margin-bottom: 24px;
            @media screen and (max-width: 991px) {
                font-size: 22px;
                line-height: 27px;
                font-weight: 500;
                margin-bottom: 21px;
            }
            @media screen and (max-width: 767px) {
                margin-bottom: 12px;
            }
        }
        p{
            font-size: 18px;
            font-weight: normal;
            line-height: 24px;
            margin-bottom: 0;
            @media screen and (min-width: 992px) {
                padding-right: 15px; 
            }
            @media screen and (max-width: 991px) {
                font-size: 16px;
                font-weight: normal;
                line-height: 22px;
                margin-bottom: 40px;
            }
        }
    }
}
.team{
    h4{
        text-align: center;
        font-size: 34px;
        margin-bottom: 4px;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Medium';
        font-weight: 500;
        @media screen and (max-width: 991px) {
            font-size: 24px;
        }
        @media screen and (max-width: 767px) {
            font-size: 22px;
        }
    }
    h2{
        font-size: 59px;
        text-align: center;
        margin-bottom: 80px;
        // font-family: 'Conv_Mark Simonson - Proxima Nova Medium';
        font-weight: 500;
        @media screen and (max-width: 991px) {
            font-size: 38px;
            margin-bottom: 35px;
        }
        @media screen and (max-width: 767px) {
            font-size: 34px;
        }
    }
    .desktop{
        @media screen and (max-width: 991px){
            display: none;
        }
        
        ul.teamList{
            position: relative;
            @media screen and (min-width: 992px){
                margin-bottom: 80px;
            }
            li{
                width: calc(100% / 4);
         
                    p {
                        font-size: 20px;
                        line-height: 30px;
                    }
            }
            &:after{
                content: "";
                position: absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                z-index: -1;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            &:nth-of-type(6n+1) {
                &:after{
                    background-image:url('../../img/team/desktop/mask-1.png');
                    transform: rotateY(180deg);
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+2) {
                &:after{
                    background-image:url('../../img/team/desktop/mask-2.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+3) {
                &:after{
                    background-image:url('../../img/team/desktop/mask-3.png');
                    background-position: left top;
                    transform: rotateY(180deg);
                }
            }
            &:nth-of-type(6n+4) {
                &:after{
                    background-image:url('../../img/team/desktop/mask-1.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+5) {
                &:after{
                    background-image:url('../../img/team/desktop/mask-2.png');
                    background-position: left top;
                    transform: rotateY(180deg);
                }
            }
            &:nth-of-type(6n+6) {
                &:after{
                    background-image:url('../../img/team/desktop/mask-2.png');
                    background-position: left top;
                }
            }
        }
       
    }
    .tablet{
        display: none;
        @media screen and (min-width: 768px) and (max-width: 991px) {
            display: block;
        }
        ul.teamList{
            position: relative;
            justify-content: space-between;
            @media screen and (max-width: 991px){
                margin-bottom: 40px;
            }
            li{
                // width: calc(100% / 3);
                padding: 60px 25px;
            }
            &:after{
                content: "";
                position: absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                z-index: -1;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            &:nth-of-type(6n+1) {
                &:after{
                    background-image:url('../../img/team/tablet/mask-1.png');
                    transform: rotateY(180deg);
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+2) {
                &:after{
                    background-image:url('../../img/team/tablet/mask-2.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+3) {
                &:after{
                    background-image:url('../../img/team/tablet/mask-3.png');
                    background-position: left top;
                    transform: rotateY(180deg);
                }
            }
            &:nth-of-type(6n+4) {
                &:after{
                    background-image:url('../../img/team/tablet/mask-1.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+5) {
                &:after{
                    background-image:url('../../img/team/tablet/mask-3.png');
                    background-position: left top;
                    transform: rotateY(180deg);
                }
            }
            &:nth-of-type(6n+6) {
                &:after{
                    background-image:url('../../img/team/tablet/mask-2.png');
                    background-position: left top;
                }
            }
        }
        
    }
    .mobile{
        @media screen and (min-width: 768px){
            display: none;
        }
        ul.teamList{
            position: relative;
            @media screen and (max-width: 767px){
                margin-bottom: 28px;
            }
            li{
                width: calc(100% / 2);
            }
            &:after{
                content: "";
                position: absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                z-index: -1;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
            &:nth-of-type(6n+1) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-1.png');
                    transform: rotateY(180deg);
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+2) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-2.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+3) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-3.png');
                    background-position: left top;
                    transform: rotateY(180deg);
                }
            }
            &:nth-of-type(6n+4) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-1.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+5) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-3.png');
                    background-position: left top;
                    transform: rotateY(180deg);
                }
            }
            &:nth-of-type(6n+6) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-2.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+7) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-3.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+8) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-2.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+9) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-3.png');
                    background-position: left top;
                }
            }
            &:nth-of-type(6n+10) {
                &:after{
                    background-image:url('../../img/team/mobile/mask-2.png');
                    background-position: left top;
                }
            }
        }
    }
    ul.teamList{
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        // background: url("../../img/mask2.png") repeat-y top center;
        // background-size: 100% auto; 
        li{
            text-align: center;
            padding: 51px 5px;
            @media screen and (max-width: 991px) {
                padding: 60px 5px;
                margin-bottom: 0;
            }
            @media screen and (max-width: 767px) {
                padding: 24px 0;
                margin-bottom: 0;
            }
            img{
                margin-bottom: 36px;
                @media screen and (max-width: 991px) {
                    width: 136px;
                    margin-bottom: 14px;
                }
                @media screen and (max-width: 767px) {
                    width: 110px;
                    margin-bottom: 17px;
                }
            }
            h5{
                margin-bottom: 2px;
                // font-family: 'Conv_Mark Simonson - Proxima Nova Bold';
                font-weight: 700;
                font-size: 28px;
                line-height: 30px;
                @media screen and (max-width: 991px) {
                    font-size: 18px;
                    line-height: 20px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
            p{
                margin-bottom: 0;
            }
        }
    }
}

// footer{
//     background-color: #fff;
// }

.coreTenets {
    max-width: 100px;
    width:100%;
}

@media screen and (min-width: 768px) {
    .customHeight {
        margin-top: 96px;
    }
 }

 .about-top-list {
     list-style: none;
     li {
        //  display: inline-flex;
         position: relative;
         img{
             display: inline-block;
             width: 40px;
             height: 40px;
             position: absolute;
             left: 0;
         }
         p{
             margin-left: 58px;
             margin-top: 4px;
             position: relative;
             display: inline-block;
         }
     }

 }