.contactUsForm {
    padding: 69px 0 75px;
    position: relative;
    background-image:  url('../../img/joinusBg.png') , url('../../img/mask.png');
    background-position:  7% 80%,center right;
    background-repeat: no-repeat, no-repeat;
    background-size: auto auto,90% 100%;
    margin-bottom: 40px;
    @media screen and (max-width: 991px) {
        padding: 36px 0 36px;
        background-size: auto 200px, 90% 100%;
        background-position: 4% 80%,center right;
    }
    @media screen and (max-width: 767px) {
        background-position: 90% top, top right;
        background-size: 150px auto, 90% 190px;
        margin-left: 10px;
    }
    h2{
        font-weight: 700;
        @media screen and (max-width: 991px) {
            font-size: 28px;
            line-height:32px;
        }
        @media screen and (max-width: 767px) {
            font-size: 22px;
        }
    }
    .subHeading{
        font-size: 20px;
        line-height: 25px;
        max-width: 270px;
        @media screen and (max-width: 991px){
            font-size: 16px;
            line-height: 20px;
        }
        @media screen and (max-width: 767px) {
            max-width: 160px;
            font-size: 14px;
            line-height: 18px;
        }
    }
    .formBlock {
        border-radius: 8px;
        box-shadow: 0 5px 25px 0 rgba(90, 103, 111, 0.17);
        margin-top: 0;
        @media screen and (max-width: 767px) {
            margin-top: 60px;
            margin-right: 10px;
        }
        .formHeader {
            padding: 18px 24px 16px;
            background: #f0f0f0;
            @media screen and (max-width: 991px) {
                padding: 10px 12px;
            }
            @media screen and (max-width: 767px) {
                padding: 9px 14x;
            }
            ul {
                padding: 0;
                display: flex;
                list-style: none;
                align-items: center;
                margin: 0;
                li {
                    flex-basis: 0;
                    flex-grow: 1;
                    @media screen and (max-width: 767px) {
                        text-align: center;
                        flex-basis : auto;
                        margin-right: 5px;
                    }
                    a {
                        padding: 11px 27px 11px 27px;                        
                        border-radius: 22px;
                        font-size: 20px;
                        line-height: 16px;
                        display: block;
                        text-align: center;
                        text-decoration: none;
                        @media screen and (max-width: 1200px){
                            font-size: 15px;
                        }
                        @media screen and (max-width: 991px) {
                            font-size: 13px;
                            padding: 5px 10px;
                        }
                        @media screen and (max-width: 767px) {
                            padding: 3px;
                            font-size: 0;
                            background: #e5e5e5;
                            display: inline-block;
                            height: 30px;
                            width: 30px;
                            vertical-align: middle;
                        }
                        &.active{
                            background: #fff;
                            font-weight: bold;
                            @media screen and (max-width: 767px) {
                                font-size: 16px;
                                padding: 7px 13px;
                                height: auto;
                                width: auto;
                            }
                        }
                        img {
                            margin-right: 10px;
                        }
                    }
                    &:nth-of-type(1){
                        a{
                            span{
                                background-image: url('../../img/schedule-demo-icon-active@3x.png');
                                background-repeat:  no-repeat;
                                background-size: cover;
                                display: inline-block;
                                width: 22px;
                                height: 20px;
                                vertical-align: middle;
                                margin-right: 10px;
                                @media screen and (max-width: 991px) {
                                    width:14px;
                                    height: 13px;
                                    margin-right: 5px;
                                }
                                @media screen and (max-width: 767px){
                                    width: 18px;
                                    height: 16px;
                                    margin: 3px 0 0;
                                }
                            }
                            &.active{
                                span{
                                    background-image: url('../../img/schedule-demo-icon-active@3x.png');
                                    @media screen and (max-width: 767px){
                                        margin-top:0;
                                        margin-right: 5px;
                                    }
                                }   
                            }
                        }
                    }
                    &:nth-of-type(2){
                        a{
                            span{
                                background-image: url('../../img/user-support-icon@3x.png');
                                background-repeat:  no-repeat;
                                background-size: cover;
                                display: inline-block;
                                width: 21px;
                                height: 21px;
                                vertical-align: middle;
                                margin-right: 10px;
                                @media screen and (max-width: 991px) {
                                    width: 14px;
                                    height: 14px;
                                }
                                @media screen and (max-width: 767px){
                                    width: 16px;
                                    height: 16px;
                                    margin: 3px 0 0;
                                }
                            }
                            &.active{
                                span{
                                    background-image: url('../../img/user-support-icon-active@3x.png');
                                    @media screen and (max-width: 767px){
                                        margin-top:0;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-of-type(3){
                        a{
                            span{
                                background-image: url('../../img/something-else-icon@3x.png');
                                background-repeat:  no-repeat;
                                background-size: cover;
                                display: inline-block;
                                width: 26px;
                                height: 24px;
                                vertical-align: middle;
                                margin-right: 10px;
                                @media screen and (max-width: 991px) {
                                    width: 15px;
                                    height: 14px;
                                }
                                @media screen and (max-width: 767px){
                                    width: 16px;
                                    height: 16px;
                                    margin: 3px 0 0;
                                }
                            }
                            &.active{
                                span{
                                    background-image: url('../../img/something-else-icon-active@3x.png');
                                    @media screen and (max-width: 767px){
                                        margin-top:0;
                                        width: 18px;
                                        height: 18px;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .formBody {
            padding: 43px 70px 44px;
            background: #fff;
            // &.userSuprt{
            //     display: none;
            // }
            // &.smthElse{
            //     display: none;
            // }
            @media screen and (max-width: 991px) {
                padding: 25px;
            }
            @media screen and (max-width: 767px) {
                padding: 30px 0px;
            }
            .formRow{
                margin-bottom: 14px;
                label{
                    padding: 18px 0 17px;
                    margin-bottom: 0;
                    text-align: right;
                    display: block;
                    @media screen and (max-width: 991px) {
                        font-size: 12px;
                        padding:7px 0;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        padding: 0;
                    }   
                }
                textarea{
                    resize: none;
                }
                .formField{
                    padding: 18px 19px 17px;
                    width: 100%;
                    border-radius: 8px;
                    box-shadow: inset -2px 2px 2px 0 rgba(215, 215, 213, 0.22);
                    border: solid 1px #dbdbdb;
                    background-color: #f5f5f5;     
                    // color: #b1b1b1;  
                    @media screen and (max-width: 991px) {
                        padding:7px 13px 7px;
                        font-size: 12px;
                    }          
                    
                    ::placeholder{
                        color: #b1b1b1;               
                    }
                }
                .submitBtn{
                    border-radius: 30px;
                    background-color: #2d3e50;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    color: #ffffff;
                    padding: 11px 40px 11px;
                    text-transform: uppercase;
                    border: 0;
                    @media screen and (max-width: 991px) {
                        font-size: 10px;
                        line-height: 18px;
                        padding: 4px 23px 4px;
                    }
                }
            }
        }
    }
}
.footer-plain {
    background-color: #ffffff;
}

.error {
    border: solid 1px #da4e4e !important;
    background-color: #fdeeee !important;
}
input.error::after {
   content: 'Required';
//    color: #da4e4e !important;
}
.errorTxt{
    color: #da4e4e !important;;
    font-size: 14px;
}
.successMsg {
//   width: 369px;
//   height: 18px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #2d3e50;
}
.patient-download-title {
    font-size: 44px ;
    margin: 60px auto 0 auto;
    max-width: 80vw; 
    line-height: normal ;
    letter-spacing: normal ;
    text-align: center;
    font-weight: 600;

    @media screen and (max-width: 991px) {
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
    }

    @media screen and (max-width: 767px){
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        text-align: left;
        max-width: 90vw;
    }
}
.patient-download-subtitle {
    font-size: 28px ;
    font-weight: normal ;
    letter-spacing: normal ;
    text-align: center ;
    margin: 0 auto;
    max-width: 80vw;

    @media screen and (max-width: 991px) {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }

    @media screen and (max-width: 767px){
        max-width: 90vw;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
    }

}
.title {
    margin-top: 32px;
}
.subtitle {
    margin-top: 32px;
    letter-spacing: normal;
    font-weight: normal;
    @media screen and (max-width:767px){
       margin-top: 12px;
       line-height: 1.31;
    }
}
.forpatientblock {
   margin-top: 67px;

   @media screen and (max-width:767px){
    margin-top: 40px;
 }
}
.blockUserInfo {
    max-width: 1440px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width:767px){
        margin-top: 60px ;
     }

}

.imgwrap {
    @media screen and (max-width:767px){
        margin-top: 28px;
        padding-left: 0px;
        padding-right: 0px;
     }
    
}
.align-right {
    justify-content: right;
}
.contentWrap {
    img{
        max-width: 156px;
    }
}

.forPatientImg {
    max-width: 600px;
    @media screen and (max-width: 991px) {
        max-width: 500px;
     }
        
}
.assistance {
    text-align:center;
    p{
        max-width: 80vw;
        margin-top: 32px;
        text-align: center;
        font-size: 24px;
        margin: 60px auto 100px auto;
        
        @media screen and (max-width: 991px) {
            max-width: 90vw;
            font-size: 20px;
            margin: 60px auto 100px auto;
        }

        @media screen and (max-width: 767px) {
            max-width: 90vw;
            font-size: 20px;
            margin: 60px auto 90px auto;
        }
    }
}
.appleUserTxt {
    @media screen and (min-width: 991px) {
        margin-left: 10%;
    }
}
.userblock {
    h1 {
        font-size: 32px;
        font-weight: bold;
        line-height: normal;
        @media screen and (max-width: 767px) {
            font-size: 22px;
        }
    }
    p{
        font-size: 20px;
        font-weight: normal ;
        line-height: normal ;
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}

.blockWrap {
    @media screen and (max-width: 767px) {
        padding-right: 24px;
        padding-left: 24px;
    }
}

