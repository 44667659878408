

.video-wrap{
    position: relative;
    .video-js {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        max-width: 1280px;
        margin: auto;
    }
    &.video-large .video-js{
        max-width: 1280px;
    }
    @media screen and (min-width: 1100px) {
        .large-image {
            max-width: 1100px;
        }
    }

    @media screen  and (max-width: 576px){
        .vjs-big-play-button {
            background-size: 40px !important;
        }
    }
}

.intelligent .swiper-button-prev:after,
.intelligent .swiper-button-next:after{
    @media screen and (max-width: 991px){
        font-size: 25px;
    }
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    max-height: 398px; 
}
.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.video1 {
    display:flex;
    width: 100%;
    max-width: 1110px;
    margin: auto;
}

.intelligent .right-information .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    margin: 0 0px 0 0px;
}
.intelligent .right-information .swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background: #8e8888;
}