.row-cont {  
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
  }
  
  /* Create two unequal columns that sits next to each other */
  /* Sidebar/left column */
  .side-cont {
    flex: 40%;
    background-color: #F1F5FF;
    padding: 4% 8%;
    flex-direction: column;

    .gerdhelp-header {
        font-size: 28px;

        .gerd-logo {
            margin-right: 5px;
        }
        .gerd {
            font-weight: bold;
            color: #1A1A1A;
        }
        .help {
            // color: #2A63B0;
        }
        .sup-scr {
            font-size: .4em !important;
            top: -1.5em;
        }
    }
    .main-header {
        margin: 40px 0;

        h2 {
            font-weight: bold;
            margin-bottom: 0;
        }

        .scnd-clr {
            color: #3C3F42;
        }
    }
    .content {
        // height: 60%;

        p {
            line-height: 3.2vh;
        }
    }
    .foot-info {
        color: #1F4B86;
        font-size: 12px;
        margin-top: 40px;
    }
  }

  .mn-clr {
    color: #2A63B0;
  }
  
  /* Main column */
  .main-cont {
    flex: 60%;
    background-color: white;
    padding: 4% 8%;

    h3 {
        font-weight: bolder;
    }

    .formBody {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .formRow {
            display: inline-flex;
            flex-direction: column;
            margin-bottom: 27px;

            .inp-label-cont {
                .inp-label {
                    color: #2A63B0;
                    font-size: 1.1em;
                }
            }
            .btn-field-cont {
                display: inline-flex;
                button {
                    width: 84px;
                    height: 40px;
                    padding: 13px 16px 13px 16px;
                    border-radius: 8px;
                    border: 1px solid #3572B9;
                    color: #3572B9;
                    margin-right: 20px;
                    background-color: #ffffff;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 12px;
                }
                .tif-selected {
                    color: #ffffff !important;
                    background-color: #3572B9 !important;
                }
            }
            .inp-field-cont {
                display: inline-flex;

                .fname {
                    width: 50% !important;
                    margin-right: 5px;
                }
                .lname {
                    width: 50% !important;
                    margin-left: 5px;
                }

                .inp-field {
                    width: 100%;
                    height: 6vh;
                    border: 1px solid #DBDBDB;
                    background-color: #F5F5F5;
                    border-radius: 10px;
                    padding: 0 15px;
                    // box-shadow: inset -2px 2px 2px rgb(215 215 213 / 22%);
                }
            }
            .inp-field-info {
                // color: #666666;
                margin-left: 4px;

                .input-info {
                    color: #666666;
                    margin-left: 8px;
                    font-size: 0.8em;
                }
            }
        }

        .chkbx-cont {
            flex-direction: row;
            margin-top: 15px;
            margin-bottom: 15px;

            .inp-filed-checkbx {

            }

            .inp-label-cont {
                margin-left: 10px;

                .inp-label {
                    color: #525252;
                    // margin-left: 8px;
                    font-size: 0.9em;

                    .link {
                        color: #525252;
                        text-decoration: underline;
                        font-weight: bold;
                    }
                }
            }
        }
        .enroll-btn-cont {
            margin-bottom: 10px;

            button:disabled {
                background-color: #3572B9;
                opacity: 0.5;
                cursor: not-allowed;
            }

            .enrollBtn {
                background-color: #3572B9;
                border-radius: 20px;
                color: white;
                border: none;
                height: 6vh;
                font-weight: bold;
            }
        }
        .form-submit-success {
            .form-success-text {
                color:#3572B9;
                font-weight: bold;
                float: right;
            }
        }
    }
  }