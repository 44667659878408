.logo {
    padding-left: 34px;
    @media screen and (max-width: 767px) {
        margin-bottom: 46px;
        margin-left: 25px;
    }
}
.contactUsBtn {
    background: #2d3e50;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 20px;
    color: #fff;
    padding: 8px 25px 7px 25px;
    outline: none;
    font-weight: 600;
    float: right;
    margin-right: 24px;
    @media screen and (max-width: 767px) {
        padding: 0 7px;
    }
    .contact-us-lg {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    .contact-us-md {
        @media screen and (min-width: 992px) {
            display: none;
        }
    }
}
.contactUsBtn:hover {
    color: #fff;
    outline: none;
    box-shadow: none;
}
.introSection {
    padding: 16px 0 28px;
    text-align: center;
    @media screen and (max-width: 767px) {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    h2{
        color: #1668BA;
        font-size: 28px;
        line-height:24px;
        margin-bottom: 36px;
        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height:30px;
            margin-bottom: 24px;
        }
    }
    h2:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #1668ba;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 7px;
        margin-right: 10px;
    }
    h2:after {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #1668ba;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 5px;
        margin-left: 4px;
    }
    .subHeading{
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        padding: 0 230px;
        @media screen and (max-width: 991px) {
            padding: 0px 50px;
        }
        @media screen and (max-width: 767px) {
            padding: 0 40px;
            font-size: 24px;
            line-height: 32px;
        }
    }
    a.see-plan {
        color: #19222c;
        font-size: 28px;
        text-decoration: underline;
        background-size: 48px auto;
        /* font-family: 'Conv_Mark Simonson - Proxima Nova Medium'; */
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        padding: 10px 35px 11px 85px;
        display: inline-block;
        text-align: center;
        background: #005bb7 url(../../img/play-button-arrowhead.png) no-repeat 35px center;
        border-radius: 31px;
        background-size: 28px auto;
        text-align: left;
        cursor: pointer;
        @media screen and (max-width: 767px) {
            font-weight: 600;
            font-size: 18.5px;
        }
    }
    a.see-plan img {
        display: none;
    }
}
.contactUsFormForAd {
    padding: 16px 0 28px;
    position: relative;
    @media screen and (max-width: 991px) {
        padding: 0;
    }
    @media screen and (max-width: 767px) {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .desc {
        background-image:url('../../img/team/desktop/Rectangle 18.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        padding: 56px 65px;
        height: auto;
        @media screen and (max-width: 991px) {
            display: none;
        }
    }
    .subHeading{
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        max-width: 900px;
        @media screen and (max-width: 767px) {
            max-width: 300px;
            font-size: 24px;
            line-height: 32px;
            text-align: left;
        }
    }
    .formSection {
        top: 0;
    }
    .formBlock {
        @media screen and (max-width: 767px) {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(85, 104, 117, 0.157725);
            border-radius: 4px;
            padding: 24px 32px;
        }
        @media screen and (max-width: 600px) {
            padding: 14px 20px;
        }
        .formHeader {
            padding: 18px 24px 16px;
            background: #f0f0f0;
            @media screen and (max-width: 991px) {
                padding: 10px 12px;
            }
            @media screen and (max-width: 767px) {
                padding: 9px 14x;
            }
            ul {
                padding: 0;
                display: flex;
                list-style: none;
                align-items: center;
                margin: 0;
                li {
                    flex-basis: 0;
                    flex-grow: 1;
                    @media screen and (max-width: 767px) {
                        text-align: center;
                        flex-basis : auto;
                        margin-right: 5px;
                    }
                    a {
                        padding: 11px 27px 11px 27px;                        
                        border-radius: 22px;
                        font-size: 20px;
                        line-height: 16px;
                        display: block;
                        text-align: center;
                        text-decoration: none;
                        @media screen and (max-width: 1200px){
                            font-size: 15px;
                        }
                        @media screen and (max-width: 991px) {
                            font-size: 13px;
                            padding: 5px 10px;
                        }
                        @media screen and (max-width: 767px) {
                            padding: 3px;
                            font-size: 0;
                            background: #e5e5e5;
                            display: inline-block;
                            height: 30px;
                            width: 30px;
                            vertical-align: middle;
                        }
                        &.active{
                            background: #fff;
                            font-weight: bold;
                            @media screen and (max-width: 767px) {
                                font-size: 16px;
                                padding: 7px 13px;
                                height: auto;
                                width: auto;
                            }
                        }
                        img {
                            margin-right: 10px;
                        }
                    }
                    &:nth-of-type(1){
                        a{
                            span{
                                background-image: url('../../img/schedule-demo-icon-active@3x.png');
                                background-repeat:  no-repeat;
                                background-size: cover;
                                display: inline-block;
                                width: 22px;
                                height: 20px;
                                vertical-align: middle;
                                margin-right: 10px;
                                @media screen and (max-width: 991px) {
                                    width:14px;
                                    height: 13px;
                                    margin-right: 5px;
                                }
                                @media screen and (max-width: 767px){
                                    width: 18px;
                                    height: 16px;
                                    margin: 3px 0 0;
                                }
                            }
                            &.active{
                                span{
                                    background-image: url('../../img/schedule-demo-icon-active@3x.png');
                                    @media screen and (max-width: 767px){
                                        margin-top:0;
                                        margin-right: 5px;
                                    }
                                }   
                            }
                        }
                    }
                    &:nth-of-type(2){
                        a{
                            span{
                                background-image: url('../../img/user-support-icon@3x.png');
                                background-repeat:  no-repeat;
                                background-size: cover;
                                display: inline-block;
                                width: 21px;
                                height: 21px;
                                vertical-align: middle;
                                margin-right: 10px;
                                @media screen and (max-width: 991px) {
                                    width: 14px;
                                    height: 14px;
                                }
                                @media screen and (max-width: 767px){
                                    width: 16px;
                                    height: 16px;
                                    margin: 3px 0 0;
                                }
                            }
                            &.active{
                                span{
                                    background-image: url('../../img/user-support-icon-active@3x.png');
                                    @media screen and (max-width: 767px){
                                        margin-top:0;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-of-type(3){
                        a{
                            span{
                                background-image: url('../../img/something-else-icon@3x.png');
                                background-repeat:  no-repeat;
                                background-size: cover;
                                display: inline-block;
                                width: 26px;
                                height: 24px;
                                vertical-align: middle;
                                margin-right: 10px;
                                @media screen and (max-width: 991px) {
                                    width: 15px;
                                    height: 14px;
                                }
                                @media screen and (max-width: 767px){
                                    width: 16px;
                                    height: 16px;
                                    margin: 3px 0 0;
                                }
                            }
                            &.active{
                                span{
                                    background-image: url('../../img/something-else-icon-active@3x.png');
                                    @media screen and (max-width: 767px){
                                        margin-top:0;
                                        width: 18px;
                                        height: 18px;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .formBody {
            h3 {
                line-height: 1.04;
                margin-bottom: 30px;
                font-size: 24px;
                margin-left: 50px;
                line-height: 35px;
                font-weight: 600;
                @media screen and (min-width: 992px) {
                    display: none;
                }
                @media screen and (max-width: 991px) {
                    margin-top: 40px;
                    font-size: 28px;
                }
                @media screen and (max-width: 767px) {
                    margin-left: 0px;
                    text-align: left;
                }
            }
            .formRow{
                margin-bottom: 14px;
                .label {
                    padding-right: 0px;
                    padding-left: 0px;
                }
                label{
                    padding-top: 3px;
                    margin-bottom: 0;
                    text-align: right;
                    display: block;
                    font-size: 16px;
                    @media screen and (max-width: 991px) {
                        font-size: 12px;
                        padding:7px 0;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        padding: 0;
                    }   
                }
                textarea{
                    resize: none;
                }
                .formField{
                    padding: 9px 19px;
                    width: 100%;
                    border-radius: 8px;
                    box-shadow: inset -2px 2px 2px 0 rgba(215, 215, 213, 0.22);
                    border: solid 1px #dbdbdb;
                    background-color: #f5f5f5;     
                    // color: #b1b1b1;  
                    @media screen and (max-width: 991px) {
                        padding:7px 13px 7px;
                        font-size: 12px;
                    }          
                    
                    ::placeholder{
                        color: #b1b1b1;               
                    }
                }
                .submitBtn{
                    border-radius: 30px;
                    background-color: #2d3e50;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    color: #ffffff;
                    padding: 11px 40px 11px;
                    text-transform: uppercase;
                    border: 0;
                    @media screen and (max-width: 991px) {
                        font-size: 10px;
                        line-height: 18px;
                        padding: 4px 23px 4px;
                    }
                }
            }
        }
    }
}
.intro {
    .intro-block {
        text-align: center;
        .img-fluid {
            height: 80px;
            width: 80px;
        }
        p {
            margin-top: 12px;
            font-size: 20px;
            font-weight: 500;;
        }
    }
    .intro-desc {
        font-size: 16px;
        padding: 28px 0 60px 0;
        line-height: 24px;
        font-weight: 500;
    }
}
.sections-lg {
    position: relative;
    @media screen and (max-width: 767px) {
        display: none;
    }
    .overlay-text {
        font-size: 16px;
        position: absolute;
        top: 124px;
        @media screen and (max-width: 991px) {
            top: 9px;
        }
        p {
            line-height: 24px;
        }
        h4 {
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }
    .overlay-text.right {
        right: 10%;
        @media screen and (max-width: 1250px) {
            right: 5%;
        }
        @media screen and (max-width: 991px) {
            right: 2%;
        }
    }
    .overlay-text.left {
        left: 10%;
        @media screen and (max-width: 1250px) {
            left: 5%;
        }
        @media screen and (max-width: 991px) {
            left: 2%;
            width: 330px;
            top: 24px;
        }
    }
    .bg-img-lg {
        height: 470px;
        background-position: 0 0;
        background-repeat: no-repeat;
        @media screen and (max-width: 991px) {
            height: 360px;
        }
    }
    .bg-img-lg.image-lg {
        @media screen and (min-width: 991px) {
            display: inherit;
        }
        @media screen and (max-width: 991px) {
            display: none;
        }
    }
    .bg-img-lg.image-md {
        @media screen and (min-width: 991px) {
            display: none;
        }
        @media screen and (max-width: 991px) {
            display: inherit;
        }
    }
}
.sections-lg.questionnaires {
    margin-bottom: 60px;
}
.sections-sm {
    @media screen and (min-width: 768px) {
        display: none;
    } 
    .overlay-text {
        padding: 24px 24px 0 24px;
        font-size: 18px;
        p {
            line-height: 24px;
            margin-bottom: 36px;
        }
        h4 {
            line-height: 30px;
            font-weight: 700;
        }
    }
    .bg-img-sm {
        max-height: 247px;
        margin: auto;
    }
    .questionnaires {
        margin-bottom: 36px;
    }
}
.testimonials {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 70px;
    padding: 0 65px;
    @media screen and (max-width: 767px) {
        padding: 0 24px;
    }
    h4 {
        margin: 60px 24px 20px 0;
        font-weight: 700;
        @media screen and (max-width: 767px) {
            margin-top: 40px;
        }
    }
    .testimonial-img {
        border-radius: 100%;
        width: 51px;
        @media screen and (max-width: 991px) {
            width: 43px;
        }
        @media screen and (max-width: 767px) {
            width: 55px;
        }
    }
    .border-right-bottom {
        border-right: solid 4px #ECF4FD;
        @media screen and (max-width: 767px) {
            border-right: none;
            border-bottom: solid 4px #ECF4FD;
        }
    }
    .test-row {
        padding-bottom: 16px;
    }
    .test-row:first-child {
        padding-right: 24px;
        padding-left: 0;
        @media screen and (max-width: 767px) {
            padding: 0;
            padding-bottom: 16px;
        }
    }
    .test-row:nth-child(2) {
        padding-left: 24px;
        padding-right: 0;
        @media screen and (max-width: 767px) {
            padding: 0;
            padding-top: 28px;
        }
    }
    .doctor-desc {
        margin-top: 8px;
    }
    .desc {
        display: inline-block;
        vertical-align: middle;
        margin-left: 13px;
    }
    .name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }
    .designation {
        font-size: 14px;
        margin-bottom: 0;
        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }
}
.footer-plain {
    background-color: #ffffff;
}

.error {
    border: solid 1px #da4e4e !important;
    background-color: #fdeeee !important;
}
.error-msg {
    color: red;
    margin-bottom: 0px;
    padding-top: 3px;
    padding-left: 5px;
}
input.error::after {
   content: 'Required';
//    color: #da4e4e !important;
}
.errorTxt{
    color: #da4e4e !important;;
    font-size: 14px;
}
.successMsg {
//   width: 369px;
//   height: 18px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #2d3e50;
}

// Edit custom input style
textarea::placeholder {
    color: #B2B2B2;
}
input::placeholder {
    color: #B2B2B2;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
form.submitted input:invalid {
    border: 1px solid red !important;
}
