
.grey-back{
   background-color: black;
   max-height: 633px;
   height: 100%;
}

.vjs-big-play-button {
   background-color: transparent;
   width: 100% !important;
   height: 100% !important;
   top: 0 !important;
   left: 0 !important;
   right: 0 !important;
   bottom: 0 !important;
   background-image:url('../../img/play-button@2x.png') !important;
   background-repeat: no-repeat !important;
   background-size: 118px !important;
   background-position: 50% calc(50% - 10px) !important;
   border: none !important; // @stylint ignore
   box-shadow: none !important; // @stylint ignore
   &:before {
     content: "" !important;
     display: none !important;
   }
   &:hover {
     background-color: transparent !important;
     opacity: .7 !important;
   }
 }
 .video-js.invisible-player {
  display: none;
}
.vjs-fullscreen.invisible-player {
  display: block;
}
 
 .react-responsive-modal-modal {
   background: none !important;
   display: flex;
   margin: auto;
   justify-content: center;
 }
 @media screen and (max-width: 767px) {
   .react-responsive-modal-modal {
      width: inherit;
    }
 }

 @media screen and (max-width: 600px) {
   .react-responsive-modal-modal {
      width: inherit;
    } 
} 

.react-responsive-modal-closeButton {
   display: none !important;
}

.vjs-big-play-centered .vjs-big-play-button {
    top: 0; 
    left: 0; 
    margin-top: 0 !important; 
    margin-left: 0 !important;
}
// .vjs-icon-play:before, .video-js .vjs-big-play-button .vjs-icon-placeholder:before, .video-js .vjs-play-control .vjs-icon-placeholder:before {
//    content:'';
// }

.card {
  background: #FFFFFF;
  box-shadow: 0px 8px 20px rgba(90, 103, 111, 0.12);
  border-radius: 44px;
  height: 440px;
  padding: 53px 30px;
  img {
    height: 60px;
    width: auto;
    margin-bottom: 40px;
  }
  p {
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #333333;
  }
}
.blue-separator {
  width: 51px;
  height: 4px;
  background: #2171C0;
  border-radius: 4px;
  margin: 25px auto;
}
.understatement {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 28px;
  text-align: center;
  color: #333333;
}
.bannerVid video {
    object-fit: cover;
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

@media screen and (min-width:767px) and (max-width:991px) {
  .banner-content {
    max-width: 500px;
  }
}


  @media screen and (min-width:315px) and (max-width:512px) {

  .react-responsive-modal-modal {
    .video-js  {
      height: 615px !important;
      width: 300px !important;
    }
  }
  }
  @media screen and (min-width:512px) and (max-width:768px) {
    .react-responsive-modal-modal {
    .video-js  {
      height: 615px !important;
      width: 500px !important;
    }
  }
   }
   @media screen and (min-width:768px) and (max-width:980px) {
    .react-responsive-modal-modal {
    .video-js  {
      height: 615px !important;
      width: 625px !important;
    }
   }
   }
   .video-js .vjs-big-play-button {
     background-color: transparent !important;
   }

   .vjs-icon-placeholder {
     display: none !important;
   }

   .vjs-has-started .vjs-icon-placeholder {
     display: inline !important;
   }

   .homeSectionLast {
     font-size : 20px;
     @media screen and (max-width: 991px) {
      font-size: 18px;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
   }
   .customWidthWhatIsIntHealth {
    @media screen and (min-width: 992px) {
      max-width: 77%;
    }
   }

   @media screen and (max-width: 768px) {
    .smallDocName {
      font-size: 18px !important;
      line-height: 22px !important;
    }
    .smallDocAOM {
      font-size:14px !important;
      line-height: 20px !important;
    }
    .smallDocQuote {
      font-size:16px !important;
      line-height: 20px !important;
    }
    .learnmoreSmall {
      font-size:16px !important;
      line-height: 20px !important;
    }
  }
